import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShimmerThumbnail } from "react-shimmer-effects";

import {
  qrcode_logo,
  silotalk_logo,
  storage_logo,
  streamdeck_logo,
  tv_logo,
  calender_apps,
  mail_apps,
  community_apps,
  assembler_apps,
  pesonadigest_apps,
  pesonaradio_apps,
  pesonapost_apps,
  pesonaos_apps,
  erp_logo,
  constructor_logo,
  suite_logo,
  canvas_logo,
  map_logo,
  wallet_logo,
  coin_exchange_logo,
  merchant_logo,
  blockchain_logo,
  bank_logo,
  sitebuilder_logo,
  marketplace_apps,
  merchant_logo_d,
  silo_publisher,
  silo_viewer,
} from "../../../../images/applogos";
import { fetchCloudSearchApi, getCookie } from "../../../../utils/Utils";

const AppDropdown = () => {
  const authToken = getCookie("authToken");
  const [login, setLogin] = useState(false);
  useEffect(() => {
    if (authToken) {
      setLogin(true);
    }
  }, [authToken]);
  const { skin } = useTheme();
  const [loading, setLoading] = useState(true);

  const [isApps, setIsApps] = useState([]);

  const appmenudropdown = async (ev) => {
    ev.preventDefault();
    const result = await fetchCloudSearchApi("api/v1/apps/get-apps");

    setIsApps([
      result?.data?.core_apps,
      result?.data?.social_apps,
      result?.data?.productivity_apps,
      result?.data?.exchange_apps,
    ]);
    setLoading(false);
  };

  const appLinks = [
    {
      href: `${login ? `https://silotalk.com/token-login/${authToken}` : "https://silotalk.com/login"}`,
      imgSrc: silotalk_logo,
      title: "Silo Talk",
      htmlDanger: false,
    },
    {
      href: "https://storage.silocloud.io/",
      imgSrc: storage_logo,
      title: "Storage",
      style: "36px",
      htmlDanger: false,
    },
    {
      href: "https://silocloud.com/silo-cloud-mail",
      imgSrc: mail_apps,
      title: "Silo Mail",
      style: "",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "https://qr.silocloud.io/",
      imgSrc: qrcode_logo,
      title: "QR Generator",
      htmlDanger: false,
    },
    {
      href: "https://tv.silocloud.io/",
      imgSrc: tv_logo,
      title: "TV",
      htmlDanger: false,
    },
    {
      href: "https://streamdeck.silocloud.io/",
      imgSrc: streamdeck_logo,
      title: "Streamdeck",
      htmlDanger: false,
    },
    {
      href: "https://site.silocloud.com/",
      imgSrc: sitebuilder_logo,
      title: "SiteBuilder",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "https://calendar.silocloud.io/",
      imgSrc: calender_apps,
      title: "Calendar",
    },
    {
      href: "https://silocloud.com/cloud-community",
      imgSrc: community_apps,
      title: "Community",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "https://store.silocloud.io/",
      imgSrc: marketplace_apps,
      title: "Marketplace",
      htmlDanger: false,
    },
    {
      href: "https://3d.silocloud.io/",
      imgSrc: silo_viewer,
      title: "3D Viewer",
      htmlDanger: false,
    },
    {
      href: "https://publisher.silocloud.io/",
      imgSrc: silo_publisher,
      title: "Publisher",
      htmlDanger: false,
    },
    { type: "header", title: "Social Apps" },
    {
      href: "https://personadigest.com/",
      imgSrc: pesonadigest_apps,
      title: `Persona <br /> Digest`,
      htmlDanger: true,
      oldsite: true,
    },
    {
      href: "https://personaradio.com/",
      imgSrc: pesonaradio_apps,
      title: "Persona <br /> Radio",
      htmlDanger: true,
      oldsite: true,
    },
    {
      href: "https://personapost.com/",
      imgSrc: pesonapost_apps,
      title: "Persona <br /> Post",
      htmlDanger: true,
      oldsite: true,
    },
    {
      href: "https://personaos.com/",
      imgSrc: pesonaos_apps,
      title: "Persona <br /> OS",
      htmlDanger: true,
      oldsite: true,
    },
    { type: "header", title: "Productivity Apps" },
    {
      href: "https://siloerp.com/",
      imgSrc: erp_logo,
      title: "Silo ERP",
      htmlDanger: false,
    },
    {
      href: "https://silosuite.com/",
      imgSrc: suite_logo,
      title: "Silo Suite",
      htmlDanger: false,
    },
    {
      href: "https://silocloud.com/silo-constructor",
      imgSrc: constructor_logo,
      title: " Constructor Tool",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "",
      imgSrc: assembler_apps,
      title: "Assembler",
      style: "42px",
      htmlDanger: false,
    },
    {
      href: "https://silocloud.com/silo-canvas/edit-image",
      imgSrc: canvas_logo,
      title: "Canvas ",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "https://mapbuilder.silocloud.com/",
      imgSrc: map_logo,
      title: "Maps",
      htmlDanger: false,
    },
    { type: "header", title: "Exchange Apps " },
    {
      href: "https://silocloud.com/wallet-dashboard",
      imgSrc: wallet_logo,
      title: "Silo Wallet",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "https://silocloud.com/enumblockchain-explorer",
      imgSrc: blockchain_logo,
      title: "Blockchain",
      htmlDanger: false,
      oldsite: true,
    },
    {
      href: "https://coin.silocloud.io/",
      imgSrc: coin_exchange_logo,
      title: "Coin Exchange",
      htmlDanger: false,
    },
    {
      href: "https://silomerchants.com/",
      imgSrc: skin === "dark" ? merchant_logo : merchant_logo_d,
      title: "Silo Merchant",
      htmlDanger: false,
    },
    {
      href: "https://silobank.com/",
      imgSrc: bank_logo,
      title: "Silo Bank",
      htmlDanger: false,
    },
  ];

  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  const handleOldClick = (href, event) => {
    event.preventDefault();
    toast.info(
      <div>
        Very soon transforming to Silo 5.0,
        <br />
        in mean time enjoy Silo 4.2
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
    );
    setTimeout(() => {
      window.location.href = href;
    }, 2000);
  };

  const handleClick = (app, event) => {
    if (app.oldsite) {
      handleOldClick(app.href, event);
    } else {
      toggle();
    }
  };

  return (
    <>
      <Dropdown isOpen={open} toggle={toggle}>
        <DropdownToggle
          tag="a"
          href="#dropdown"
          onClick={appmenudropdown}
          className="dropdown-toggle nk-quick-nav-icon"
        >
          <div className="icon-status icon-status-na">
            <Icon name="menu-circled" />
          </div>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg border-0">
          <div className="dropdown-body apps-dropdown overflow-auto">
            {loading ? (
              <div className="row my-3">
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
                <div className="col-4">
                  <ShimmerThumbnail height={70} rounded />
                </div>
              </div>
            ) : (
              isApps.map((main_app, main_index) => (
                <React.Fragment key={main_index}>
                  <h6 className="px-3 pt-2 text-muted fw-normal">{main_app?.label}</h6>
                  <ul className="list-apps">
                    {main_app?.data.map((app, index) => (
                      <li key={index}>
                        <Link
                          to={
                            app?.name === "Talk" && login
                              ? `https://silotalk.com/token-login/${authToken}`
                              : app?.project_link
                          }
                          onClick={(event) => handleClick(app, event)}
                        >
                          <span className="list-apps-media">
                            <img src={app?.image_link} alt={app?.name} />
                          </span>
                          <div className="list-apps-title" dangerouslySetInnerHTML={{ __html: app?.name }} />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ))
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
      {/* <ToastContainer /> */}
    </>
  );
};

export default AppDropdown;
