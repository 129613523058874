import React, { useEffect, useState, useRef, useContext } from "react";
import InboxMessageHeader from "./InboxMessageHeader";
import SimpleBar from "simplebar-react";
import InboxReplyItem from "./InboxReplyItem";
import { contacts, formTemplates, inboxLabels, inboxList } from "./InboxData";
import { Button, Icon, TooltipComponent } from "../../../components/Component";
import { postRequest } from "../../../utils/api_helper";
import { MyContext } from "../../ContextMail";
import MessageViewShimmer from "../../MessageViewShimmer";
import ReplyForm from "./ReplyForm";

const InboxMessages = (
  {
    // mailId,
    // data = inboxList,
    // setMailId,
    // toggleMessageView,
    // onFavoriteClick,
    // deleteInbox,
    // onArchiveClick,
    // onSpamClick,
    // outerLabels,
  }
) => {
  // const [mailId, setMailId] = useState(2);
  const [data, setData] = useState(null);

  const { toggleMessageView, selectedId: mailId, setSelectedId: setMailId, setRecentRead } = useContext(MyContext);

  const [mailData, setMailData] = useState();
  const [inboxText, setInboxText] = useState("");
  const [templateList, setTemplateList] = useState(formTemplates);
  const [attachmentList, setAttachmentList] = useState([]);
  const [tagifyOptions, setTagifyOptions] = useState([{ value: "info@softnio.com" }]);
  const [ccTagify, setCCTagify] = useState({
    toggle: false,
    value: [""],
  });
  const [bccTagify, setBCCTagify] = useState({
    toggle: false,
    value: [""],
  });

  const [replyingTo, setReplyingTo] = useState("");

  // useEffect(() => {
  //   let defaultData = data.find((item) => item.id === 2);
  //   // console.log(defaultData, "default data");
  //   let sentTo = defaultData.message.reply[0].to;
  //   // let user = contacts.find((el) => el.id === sentTo.user);
  //   setMailData(defaultData);
  //   // if (user) {
  //   //   setTagifyOptions([{ value: user.mail }]);
  //   // } else {
  //   //   setTagifyOptions([{ value: sentTo.mail }]);
  //   // }
  //   setTagifyOptions([{ value: sentTo.mail }]);
  // }, [mailId, data]);

  const getMail = async (id) => {
    try {
      const newData = new FormData();
      newData.append("email_id", id);
      const response = await postRequest("/mail/get-email", newData);

      if (response.status) {
        setMailData(response?.data);
        setTagifyOptions(
          response?.data.message.meta.sent
            ? response?.data.message.reply[0].to.mail
            : response?.data.message.reply[0].mail
        );
        if (!response?.data.message.meta?.read) {
          try {
            const newdata = new FormData();
            newdata.append("read_id", id);
            newdata.append("action", "read");
            const response = await postRequest("/mail/read-email", newdata);
            if (response.status) setRecentRead(id);
          } catch (error) {
            console.log("error message", error);
          }

          // let defaultData = data;
          // // console.log(data, "96");
          // let item = defaultData.findIndex((item) => item.id === id);
          // defaultData[item].message.meta.read = !defaultData[item].message.meta.read;
          // setData([...defaultData]);
        }
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  useEffect(() => {
    getMail(mailId);
  }, [mailId]);

  useEffect(() => {
    if (mailData) {
      const lastRecipientReply = mailData.message.reply
        .slice() // Create a copy of the array to avoid mutating the original
        .reverse() // Reverse the array to start from the last element
        .find((reply) => reply.isRecipient === true); // Find the first element with isRecipient true

      if (lastRecipientReply) {
        setReplyingTo(lastRecipientReply.replyId);
      }
    }
  }, [mailData]);

  const removeTag = (text) => {
    let defaultData = mailData;
    let tags = defaultData.message.meta.tags.filter((item) => item.text !== text);
    defaultData.message.meta.tags = tags;
    setMailData({ ...defaultData });
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const setRepMsg = (id) => {
    setReplyingTo(id);
    scrollToBottom();
  };

  const replyTo = (userMail) => {
    setTagifyOptions([{ value: userMail }]);
    scrollToBottom();
  };

  const forwardTo = () => {
    setTagifyOptions([""]);
    scrollToBottom();
  };

  const onFavoriteClick = async (id) => {
    setMailData((prev) => {
      return {
        ...prev,
        message: {
          ...prev.message,
          meta: {
            ...prev.message.meta,
            favourite: !prev.message.meta.favourite,
          },
        },
      };
    });

    try {
      const data = new FormData();
      data.append("favourite_id", id);
      const response = await postRequest("/mail/favourite-inbox-list-email", data);
      if (response?.status) {
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const deleteMessage = (id) => {
    let defaultData = mailData;
    let defaultReply = defaultData.message.reply;
    if (defaultReply.length === 1) {
      //setMailData({ ...defaultData });
      // deleteInbox(mailData.id);
      // console.log("dele");
    } else {
      defaultReply = defaultData.message.reply.filter((item) => item.replyId !== id);
      defaultData.message.reply = defaultReply;
      setMailData({ ...defaultData });
    }
  };

  return (
    <React.Fragment>
      {!mailData ? (
        <MessageViewShimmer />
      ) : (
        <React.Fragment>
          {/* <InboxMessageHeader
            toggleMessageView={toggleMessageView}
            deleteInbox={deleteInbox}
            onArchiveClick={onArchiveClick}
            onSpamClick={onSpamClick}
            msgId={mailData.id}
            setMailId={setMailId}
            localLabel={mailData.message.meta.tags}
            outerLabels={outerLabels}
            mailData={mailData}
            setMailData={setMailData}
          /> */}
          <InboxMessageHeader
            toggleMessageView={toggleMessageView}
            msgId={mailData.id}
            setMailId={setMailId}
            localLabel={mailData.message.meta.tags}
            outerLabels={inboxLabels}
            mailData={mailData}
            setMailData={setMailData}
          />
          {/* <MessageViewShimmer /> */}
          <SimpleBar className="nk-ibx-reply nk-reply" scrollableNodeProps={{ ref: messagesEndRef }}>
            <div className="nk-ibx-reply-head">
              <div>
                <h4 className="title">{mailData.message.subject ? mailData.message.subject : "(no subject)"}</h4>
                <ul className="nk-ibx-tags g-1">
                  {mailData.message.meta.tags.map((tagItem, index) => (
                    <li className="btn-group is-tags" key={index}>
                      <Button color={tagItem.color} size="xs" className="btn-dim">
                        {tagItem.text}
                      </Button>
                      <Button
                        color={tagItem.color}
                        size="xs"
                        className="btn-icon btn-dim"
                        onClick={() => removeTag(tagItem.text)}
                      >
                        <Icon name="cross"></Icon>
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
              <ul className="d-flex g-1">
                {/* <li className="d-none d-sm-block">
                  <TooltipComponent
                    tag="a"
                    containerClassName="btn btn-icon btn-trigger btn-tooltip"
                    icon="printer"
                    id="ibx-msg-print"
                    direction="left"
                    text="Print"
                  />
                </li> */}
                <li className="me-n1">
                  <div className="asterisk">
                    <a
                      href="#item"
                      className={`btn btn-trigger btn-icon btn-tooltip ${
                        mailData.message.meta.favourite ? "active" : ""
                      }`}
                      onClick={(ev) => {
                        ev.preventDefault();
                        onFavoriteClick(mailData.id);
                      }}
                    >
                      <Icon name={`${mailData.message.meta.favourite ? "star-fill" : "star"}`}></Icon>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="nk-ibx-reply-group">
              {mailData.message.reply.map(
                (replyItem, index) =>
                  replyItem.isRecipient && (
                    <>
                      <InboxReplyItem
                        reply={replyItem}
                        key={index}
                        deleteMessage={deleteMessage}
                        // replyTo={replyTo}
                        // forwardTo={forwardTo}
                        mailData={mailData}
                        setRepMsg={setRepMsg}
                        replies={mailData.message.reply}
                      />
                      {/* {setReplyingTo(replyItem.replyId)} */}
                    </>
                  )
              )}
            </div>

            <ReplyForm
              mailData={mailData}
              replyingTo={replyingTo}
              setMailData={setMailData}
              messagesEndRef={messagesEndRef}
              getMail={getMail}
            />
          </SimpleBar>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default InboxMessages;
