import React from "react";
import { ShimmerCircularImage, ShimmerPostDetails, ShimmerSocialPost, ShimmerThumbnail } from "react-shimmer-effects";

const MessageViewShimmer = () => {
  return (
    <div>
      <div>
        {Array(1)
          .fill("")
          .map((_, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                padding: "10px",
                borderBottom: "1px solid #ddd",
                justifyContent: "space-between",
              }}
            >
              <div className="d-flex">
                {/* Checkbox Shimmer */}
                <div style={{ marginRight: "15px" }}>
                  <div className="shimmer-checkout">
                    <ShimmerThumbnail height={25} width={1} rounded />
                  </div>
                </div>
                <div style={{ marginRight: "15px" }}>
                  <div className="shimmer-checkout">
                    <ShimmerThumbnail height={25} width={1} rounded />
                  </div>
                </div>

                {/* Profile Image Shimmer */}
                <div style={{ marginRight: "15px" }}>
                  <ShimmerCircularImage size={30} />
                </div>
              </div>

              {/* Time Shimmer */}
              <div style={{ marginLeft: "15px", width: "50px" }}>
                <ShimmerThumbnail height={25} width={1} rounded />
              </div>
            </div>
          ))}
      </div>
      <div className="">
        <ShimmerSocialPost type="text" title />
      </div>
    </div>
  );
};

export default MessageViewShimmer;
