import React, { useContext, useState } from "react";
import { Icon, TooltipComponent } from "../../../components/Component";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledTooltip } from "reactstrap";
import archive from "../../../images/applogos/images/archive.png";
import unarchive from "../../../images/applogos/images/unarchive.png";
import trash from "../../../images/applogos/images/trash.png";
import spam from "../../../images/applogos/images/spam.png";
import menu from "../../../images/applogos/images/menu.png";
import search from "../../../images/applogos/images/search.png";
import prev from "../../../images/applogos/images/prev.png";
import next from "../../../images/applogos/images/next.png";
import tag from "../../../images/applogos/images/tag.png";
import move from "../../../images/applogos/images/move.png";
import { postRequest } from "../../../utils/api_helper";
import { MyContext } from "../../ContextMail";

const InboxMessageHeader = ({
  msgId,
  setMailId,
  // onSpamClick,
  toggleMessageView,
  // deleteInbox,
  // onArchiveClick,
  localLabel,
  outerLabels,
  mailData,
  setMailData,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);

  const { setRecentDelete } = useContext(MyContext);

  const onSearchChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };

  const toggleSearch = () => {
    setSearchToggle(!searchToggle);
  };

  const nextMessage = () => {
    if (msgId === 12) {
      setMailId(1);
    } else {
      setMailId(msgId + 1);
    }
  };

  const prevMessage = () => {
    if (msgId !== 1) {
      setMailId(msgId - 1);
    } else {
      setMailId(12);
    }
  };

  const changeTagsOnCheck = (checked, label) => {
    let defaultData = mailData;
    let defaultTags = mailData.message.meta.tags;
    if (checked) {
      defaultTags.push(label);
    } else {
      defaultTags = defaultTags.filter((item) => item.text !== label.text);
      mailData.message.meta.tags = defaultTags;
    }
    setMailData({ ...defaultData });
  };

  const onArchiveClick = async (id) => {
    let defaultData = mailData;
    defaultData.message.meta.archived = !defaultData.message.meta.archived;
    setMailData({ ...defaultData });
    try {
      const data = new FormData();
      data.append("archieve_id", id);
      const response = await postRequest("/mail/change-archive-status", data);
      // if (response?.status) {
      // }
    } catch (error) {
      console.log("error message", error);
    }
  };

  const onDeleteClick = async (id) => {
    let defaultData = mailData;
    defaultData.message.meta.trash = !defaultData.message.meta.trash;
    setMailData({ ...defaultData });
    toggleMessageView();
    try {
      const data = new FormData();
      data.append("email_id", id);
      const response = await postRequest("/mail/delete-email", data);
      if (response?.status) {
        setRecentDelete(id);
        // setTimeout(() => {
        // }, 500);
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  return (
    <div className="nk-ibx-head">
      <div className="nk-ibx-head-actions">
        <ul className="nk-ibx-head-tools g-1">
          {/* <li className="ms-n2"> */}
          <li className="ms-n2" onClick={() => toggleMessageView()}>
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
              }}
              className="btn btn-icon btn-trigger nk-ibx-hide"
            >
              <Icon name="arrow-left"></Icon>
            </a>
          </li>
          {/* <li> */}
          <li onClick={() => onArchiveClick(msgId)}>
            {/* {console.log(mailData.message.meta.archived, mailData)} */}
            {mailData.message.meta.archived ? (
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-trigger"
                // icon="archived"
                path={unarchive}
                id="ibx-msg-archive"
                direction="top"
                text="Unarchive"
              />
            ) : (
              <TooltipComponent
                tag="a"
                containerClassName="btn btn-icon btn-trigger"
                // icon="archived"
                path={archive}
                id="ibx-msg-archive"
                direction="top"
                text="Archive"
              />
            )}
          </li>
          <li className="d-none d-sm-block">
            {/* <li className="d-none d-sm-block" onClick={() => onSpamClick(msgId)}> */}
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger"
              // icon="report"
              path={spam}
              id="ibx-msg-spam"
              direction="top"
              text="Spam"
            />
          </li>
          {/* <li> */}
          <li onClick={() => onDeleteClick(msgId)}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger"
              path={trash}
              id="ibx-msg-trash"
              direction="top"
              text="Delete"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              {/* <DropdownToggle
                tag="a"
                onClick={(ev) => ev.preventDefault()}
                className="btn btn-icon btn-trigger"
                color="transparent"
                id="dropdownToggleLabel"
              >
                <span className="header-body-img">
                  <img src={tag} alt="search" height={15} />
                </span>
                <UncontrolledTooltip autohide={false} placement="top" target="dropdownToggleLabel">
                  Label
                </UncontrolledTooltip>
              </DropdownToggle> */}
              <DropdownMenu>
                <ul className="link-tidy no-bdr sm">
                  {outerLabels.map((labelItem, index) => {
                    let findLabel = localLabel.find((item) => item.text === labelItem.text);
                    return (
                      <li key={index}>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={findLabel ? true : false}
                            id={`check-${labelItem.text}`}
                            onChange={(e) => changeTagsOnCheck(e.target.checked, labelItem)}
                          />
                          <label className="custom-control-label" htmlFor={`check-${labelItem.text}`}>
                            <div className={`dot dot-md dot-${labelItem.color} me-1`}></div>
                            {labelItem.text}
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          {/* <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                href="#toggle"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <span className="header-body-img">
                  <img src={menu} alt="menu" height={18} />
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                      <span className="header-body-img">
                        <img src={move} alt="trash" height={16} />
                      </span>
                      <span>Move to</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                      <span className="header-body-img">
                        <img src={trash} alt="trash" height={16} />
                      </span>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                      <span className="header-body-img">
                        <img src={archive} alt="archive" height={16} />
                      </span>
                      <span>Archive</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li> */}
        </ul>
      </div>
      <div>
        <ul className="nk-ibx-head-tools g-1">
          <li className="d-none d-sm-block" onClick={() => prevMessage()}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger btn-tooltip"
              path={prev}
              id="ibx-msg-prev"
              direction="top"
              text="Prev"
            />
          </li>
          <li className="d-none d-sm-block" onClick={() => nextMessage()}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-icon btn-trigger btn-tooltip"
              path={next}
              id="ibx-msg-next"
              direction="top"
              text="Next"
            />
          </li>
          {/* <li>
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
                toggleSearch();
              }}
              className="btn btn-trigger btn-icon search-toggle toggle-search"
            >
              <span className="header-body-img">
                <img src={search} alt="search" height={18} />
              </span>
            </a>
          </li> */}
        </ul>
      </div>
      {/* <div className={`search-wrap ${searchToggle ? "active" : ""}`}>
        <div className="search-content">
          <a
            href="#item"
            onClick={(ev) => {
              ev.preventDefault();
              toggleSearch();
            }}
            className="search-back btn btn-icon toggle-search"
          >
            <span className="header-body-img">
              <img src={search} alt="search" height={18} />
            </span>
          </a>
          <input
            type="text"
            className="form-control border-transparent form-focus-none"
            placeholder="Search by user or message"
            //onChange={(e) => onSearchChange(e)}
          />
          <button className="search-submit btn btn-icon">
            <span className="header-body-img">
              <img src={search} alt="search" height={18} />
            </span>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default InboxMessageHeader;
