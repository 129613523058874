import { useContext } from "react";
import { MyContext } from "../../ContextMail";

const RepliableMail = ({ email }) => {
  const { openDraftCompose } = useContext(MyContext);
  const data = {
    message: {
      reply: [
        {
          attachment: [],
          to: {
            recipient: [
              {
                mail: email,
              },
            ],
          },
        },
      ],
    },
  };

  return (
    <a
      href={`mailto:${email}`}
      onClick={(e) => {
        e.preventDefault();
        openDraftCompose(data);
      }}
    >
      &lt;{email}&gt;
    </a>
  );
};

export default RepliableMail;
