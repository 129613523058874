import React, { useContext, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import InboxForm from "./InboxForm";
import { Icon, UserAvatar, Button, LinkItem, LinkList } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { colourOptions } from "./InboxData";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Badge } from "reactstrap";
import LabelFormModal from "./LabelFormModal";
import ContactsFormModal from "./ContactsFormModal";
import { NavLink } from "react-router-dom";
import { MyContext } from "../../ContextMail";
import { getPostRequest } from "../../../utils/api_helper";

const InboxAside = ({
  navData,
  currentTab,
  aside,
  setAside,
  setCurrentTab,
  data,
  setData,
  contact,
  setContact,
  labels,
  setLabels,
  setFilterLabel,
  // setMessageView,
}) => {
  const [composeModal, setComposeModal] = useState(false);
  const [composeMail, setComposeMail] = useState("");

  const { setMessageView, draftComposeData, draftComposeModal, setCheckMail, fetchTabData, tabCounts } =
    useContext(MyContext);

  const [contactModal, setContactModal] = useState(false);

  const [labelModal, setLabelModal] = useState({
    edit: false,
    add: false,
  });
  const [labelFormData, setLabelFormData] = useState({
    label: "",
    theme: {
      value: "primary",
      label: "Primary",
    },
  });

  const [editLabelFormData, setEditLabelFormData] = useState({
    editId: 0,
    label: "",
    theme: {
      value: "primary",
      label: "Primary",
    },
  });

  const resetLabelForm = () => {
    setLabelFormData({
      label: "",
      theme: {
        value: "primary",
        label: "Primary",
      },
    });
  };

  const resetEditLabelForm = () => {
    setEditLabelFormData({
      editId: 0,
      label: "",
      theme: {
        value: "primary",
        label: "Primary",
      },
    });
  };

  const onLabelFormCancel = () => {
    setLabelModal({
      edit: false,
      add: false,
    });
    resetLabelForm();
  };

  useEffect(() => {
    fetchTabData();
  }, []);

  // const fetchTabData = async () => {
  //   const response = await getPostRequest("/mail/show-labels-count");
  //   if (response?.status) {
  //     setTabCounts({
  //       Inbox: response.inbox_count,
  //       Draft: response.draft_count,
  //       Trash: response.trash_count,
  //       Spam: response.spam_count,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   fetchTabData();
  // }, [countRefresh]);

  const onLabelFormSubmit = (form) => {
    const { label, theme } = form;
    let dataObject = {
      id: Math.floor(Math.random() * 4000) + labels.length + 1,
      text: label,
      color: theme.value,
    };
    setLabels([dataObject, ...labels]);
    setLabelModal({
      edit: false,
      add: false,
    });
    resetLabelForm();
  };

  const onLabelEditClick = (id) => {
    labels.forEach((item) => {
      if (item.id === id) {
        const selected_theme = colourOptions.filter(function (option) {
          return option.value === item.color;
        });
        setLabelModal({ ...labelModal, edit: true });
        setEditLabelFormData({
          label: item.text,
          theme: {
            value: selected_theme[0].value,
            label: selected_theme[0].label,
          },
          editId: item.id,
        });
      }
    });
  };

  const onLabelEditFormSubmit = (form) => {
    const { label, theme } = form;
    let dataObject = {
      id: editLabelFormData.editId,
      text: label,
      color: theme.value,
    };
    let defaultData = labels;
    let foundData = defaultData.findIndex((item) => item.id === editLabelFormData.editId);
    defaultData[foundData] = dataObject;
    setLabels([...defaultData]);
    resetEditLabelForm();
    setLabelModal({
      edit: false,
    });
  };

  const [conatctFormData, setConatctFormData] = useState({
    name: "",
    theme: "primary",
    designation: "",
    mail: "",
  });

  const resetConatctForm = () => {
    setConatctFormData({
      name: "",
      theme: "primary",
      designation: "",
      mail: "",
    });
  };

  const onConatctFormCancel = () => {
    setContactModal(false);
    resetConatctForm();
  };

  const onContactFormSubmit = (data) => {
    let dataObject = {
      id: Math.floor(Math.random() * 4000) + contact.length + 1,
      name: data.name,
      theme: "primary",
      designation: data.designation,
      mail: data.mail,
    };
    setContact([dataObject, ...contact]);
    setContactModal(false);
  };

  const deleteLabel = (id) => {
    let defaultVal = labels.filter((item) => item.id !== id);
    setLabels(defaultVal);
  };

  return (
    <div className={`nk-ibx-aside toggle-screen-lg ${aside ? "content-active" : ""}`}>
      <div className="">
        <a
          href="#toggle"
          onClick={(ev) => {
            ev.preventDefault();
            setComposeMail("");
            setComposeModal(true);
          }}
          className="link bg-primary text-white m-2 ms-lg-4 tr3s compose-btn rounded-2"
          style={{ padding: ".7rem", paddingRight: "13px" }}
        >
          <Icon name="pen"></Icon> <span>Compose</span>
        </a>
      </div>
      {/* sidebar */}
      <SimpleBar className="nk-ibx-nav">
        <React.Fragment>
          <ul className="nk-ibx-menu px-0">
            {navData.map((item) => (
              // <li className={currentTab === item.name ? "active" : ""} key={item.name}>
              //   <a
              //     className="nk-ibx-menu-item"
              //     href="#menu-item"
              //     onClick={(ev) => {
              //       ev.preventDefault();
              //       setCurrentTab(item.name);
              //       setAside(false);
              //       setMessageView(false);
              //     }}
              //   >
              //     {/* <Icon name={item.icon}></Icon> */}
              //     <img src={item.imgName} alt={item.icon} height={16} />
              //     <span className="nk-ibx-menu-text ps-2">{item.name}</span>
              //     {item.badge && (
              //       <Badge color={item.badge.theme} pill>
              //         {getTabDataNum(item.name)}
              //       </Badge>
              //     )}
              //   </a>
              // </li>
              <li
                key={item.name}
                onClick={() => {
                  setMessageView(false);
                  setCheckMail([]);
                  fetchTabData();
                }}
              >
                <NavLink to={"/" + item.path} className="nk-ibx-menu-item">
                  {/* <Icon name={item.icon}></Icon> */}
                  <img src={item.imgName} alt={item.icon} height={16} />
                  <span className="nk-ibx-menu-text ps-2">{item.name}</span>
                  {item.badge && (
                    <Badge color={item.badge.theme} pill>
                      {tabCounts[item.name] || 0}
                    </Badge>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </React.Fragment>
      </SimpleBar>
      {/* sidebar */}
      <InboxForm
        mailData={data}
        setMailData={setData}
        toggleModal={setComposeModal}
        composeMail={composeMail}
        composeState={composeModal || draftComposeModal}
        draftData={draftComposeData}
      />
      <LabelFormModal
        modal={labelModal.add}
        modalAction="add"
        formData={labelFormData}
        setFormData={setLabelFormData}
        closeModal={onLabelFormCancel}
        onSubmit={onLabelFormSubmit}
      />
      <LabelFormModal
        modal={labelModal.edit}
        modalAction="edit"
        formData={editLabelFormData}
        setFormData={setEditLabelFormData}
        closeModal={onLabelFormCancel}
        onSubmit={onLabelEditFormSubmit}
      />
      <ContactsFormModal
        modal={contactModal}
        formData={conatctFormData}
        setFormData={setConatctFormData}
        closeModal={onConatctFormCancel}
        onSubmit={onContactFormSubmit}
      />
    </div>
  );
};

export default InboxAside;
