import Cookies from "js-cookie";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (oldstring) => {
  const string = oldstring.toUpperCase();
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getCookie = (cookieName, defaultValue = "") => {
  let value = defaultValue;
  let tempValue = Cookies.get(cookieName);
  if (tempValue) {
    value = tempValue;
  }
  return value;
};
export const getInitials = (text) => {
  const words = text.split(" ");

  // Get the first word (or an empty string if no words are present)
  const firstWord = words[0] || "";

  // Get the second word, if available, or an empty string
  const secondWord = words.length > 1 ? words[1] : "";

  // Get the first letter of the first word and capitalize it
  const firstInitial = firstWord.charAt(0).toUpperCase();

  // Get the first letter of the second word, if available, and capitalize it
  const secondInitial = secondWord.charAt(0).toUpperCase();

  // Concatenate the initials together
  return firstInitial + (secondInitial || firstWord.charAt(1).toUpperCase());
};
export const base64ToBlob = (base64Data, contentType = "", sliceSize = 512) => {
  // console.log(base64Data);
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export function formatFileSize(fileSize) {
  if (fileSize >= 1024 * 1024 * 1024 * 1024) {
    return (fileSize / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
  } else if (fileSize >= 1024 * 1024 * 1024) {
    return (fileSize / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  } else if (fileSize >= 1024 * 1024) {
    return (fileSize / (1024 * 1024)).toFixed(2) + " MB";
  } else if (fileSize >= 1024) {
    return (fileSize / 1024).toFixed(2) + " KB";
  } else {
    return fileSize.toFixed(2) + " bytes";
  }
}

export function getDateAfterTwoWeeks(startDate) {
  const msPerDay = 1000 * 60 * 60 * 24;
  const daysToAdd = 14;

  const startDateMs = startDate.getTime();
  const endDateMs = startDateMs + daysToAdd * msPerDay;

  const endDate = new Date(endDateMs);

  return endDate;
}
export const fetchCloudSearchApi = async (route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export const fetchAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

// formating times

export function formatDateTime(utcDateStr) {
  // Parse the input UTC date string
  const utcDate = new Date(utcDateStr);
  // Append "UTC" to ensure it's treated as UTC

  // Get the current date in local time
  const now = new Date();

  // Function to format the time as '10:12 PM'
  const formatTime = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Function to format the date as 'Aug 23'
  const formatMonthDay = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Function to format the date as '3/3/18'
  const formatMonthDayYear = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Check if the date is today
  if (
    utcDate.getDate() === now.getDate() &&
    utcDate.getMonth() === now.getMonth() &&
    utcDate.getFullYear() === now.getFullYear()
  ) {
    return formatTime(utcDate);
  }

  // Check if the date is within the current year
  if (utcDate.getFullYear() === now.getFullYear()) {
    return formatMonthDay(utcDate);
  }

  // Otherwise, return the date as '3/3/18'
  return formatMonthDayYear(utcDate);
}

export function formatWithRelativeTime(utcDateStr) {
  // Parse the input ISO 8601 UTC date string
  const utcDate = new Date(utcDateStr); // JavaScript automatically interprets this as UTC

  // Get the current date in local time
  const now = new Date();

  // Calculate the difference in time (milliseconds) and convert to days
  const diffInMs = now - utcDate;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInHours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
  const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);

  // Function to format the time as '2:27 PM'
  const formatTime = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Function to format the date as 'Fri, Aug 23'
  const formatDateWithDay = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Function to format the full date with time 'Fri, Aug 23, 3:54 PM'
  const formatFullDateWithTime = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Function to format date and time with year 'Tue, Mar 6, 2018, 10:54 PM'
  const formatFullDateWithYearAndTime = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Ensure local time zone
    }).format(date);
  };

  // Calculate the relative time string
  let relativeTime;
  if (utcDate.getFullYear() !== now.getFullYear()) {
    // If it's from a different year, show full date with year and time
    return formatFullDateWithYearAndTime(utcDate);
  } else if (diffInDays === 0) {
    if (diffInHours > 0) {
      relativeTime = `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else if (diffInMinutes > 0) {
      relativeTime = `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    } else {
      relativeTime = "just now";
    }
    return `${formatTime(utcDate)} (${relativeTime})`;
  } else if (diffInDays <= 10) {
    relativeTime = `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    return `${formatFullDateWithTime(utcDate)} (${relativeTime})`;
  } else {
    return formatFullDateWithTime(utcDate);
  }
}

export function formatReplyDateTime(utcDate) {
  const date = new Date(utcDate);

  // Options for formatting the date
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // Options for formatting the time
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Format the date and time separately
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  // Combine the formatted strings
  return `On ${formattedDate} at ${formattedTime}`;
}
