import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import "../pages/css/mail.css";
import Cookies from "js-cookie";
import FourteenDaysCountDown from "../pages/hooks/FourteenDaysCountDown";
// import MobileFooter from "./footer/MobileFooter";
import { Alert, Toast, ToastBody } from "reactstrap";
import { Icon } from "../components/Component";
export const GloabalContext = createContext();

const Layout = ({ title, app, ...props }) => {
  const [toastVisible, setToastVisible] = useState(false);
  const [action, setAction] = useState("");
  const [undoMessage, setUndoMessage] = useState(() => {
    // console.log("abc");
  });
  const [showMessage, setShowMessage] = useState();
  const [authChecking, setAuthChecking] = useState(true);
  const [files, setFiles] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  // const { storageFiledAlert } = useContext(MyContext);

  const endDate = new Date("2024-05-30 09:38:09"); // Replace with your desired end date
  const { days, hours, minutes, seconds } = FourteenDaysCountDown(endDate);
  const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, []);
  if (authChecking) return <>Redirecting to authentication</>;

  const showToast = (message, action, undoMessage, time) => {
    setAction(action);
    setUndoMessage(() => undoMessage);
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, time);

    setShowMessage(message);
  };

  return (
    <>
      <GloabalContext.Provider
        value={{
          files,
          setFiles,
          toastVisible,
          setToastVisible,
          showMessage,
          setShowMessage,
          loadingList,
          setLoadingList,
          undoMessage,
          showToast,
        }}
      >
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps">
          <AppMain>
            {/* <Sidebar fixed /> */}
            <AppWrap>
              <Header fixed />
              {/* <ToastContainer hideProgressBar autoClose={1200} /> */}
              {/* <ToastContainer /> */}
              {/* {storageFiledAlert && (
                <Alert className="alert-icon" color="primary">
                  <Icon name="alert-circle" />
                  Your storage has been filled with 90% or above and you won't be able to send attachment's in your mail
                  please upgrade the storage.
                </Alert>
              )} */}
              {toastVisible && (
                <div
                  className="show-toaster toast-container"
                  style={{ position: "absolute", bottom: "5rem", left: "1rem" }}
                >
                  <Toast className="border-0 px-3 ">
                    <ToastBody className="d-flex justify-content-between">
                      <small>{showMessage}</small>
                      {action && (
                        <Link
                          to=""
                          className="ms-3"
                          onClick={() => {
                            undoMessage();
                          }}
                        >
                          {action}
                        </Link>
                      )}
                    </ToastBody>
                  </Toast>
                </div>
              )}
              <Outlet />
              <Footer fixed />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
