import React, { useContext, useEffect, useState } from "react";
import InboxBodyHead from "../app/inbox/InboxBodyHead";
import ContentBody from "./ContentBody";
import { inboxList } from "../app/inbox/InboxData";
import { MyContext } from "../ContextMail";
import { fetchGet } from "../../utils/api_helper";
import InboxMessages from "../app/inbox/InboxMessages";

const SentList = () => {
  const [data, setData] = useState(null);
  const { messageView, refresh, setRefresh } = useContext(MyContext);

  const getMailList = async () => {
    try {
      const response = await fetchGet("/mail/get-sent-mail-list");
      if (response.status) {
        setData(response?.data);
        setRefresh(false);
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  useEffect(() => {
    getMailList();
  }, [refresh]);

  if (messageView) {
    return <InboxMessages />;
  }

  return (
    <>
      <InboxBodyHead data={data} setData={setData} />
      <ContentBody data={data} setData={setData} emptyMessage={"No sent mails. Try sending some messages."} />
    </>
  );
};

export default SentList;
