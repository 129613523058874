import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from "reactstrap";
import { Icon } from "../components/Component";
import QuillEditor from "./QuillEditor";
import { MyContext } from "./ContextMail";
import { getPostRequest, postRequest } from "../utils/api_helper";
import { ShimmerText } from "react-shimmer-effects";
import { toast } from "react-toastify";

const Signature = ({ onSendData }) => {
  const { setSignature, signature } = useContext(MyContext);
  const [inboxText, setInboxText] = useState(""); // Inbox text (signature content)
  const [modal, setModal] = useState(false);
  const [heading, setHeading] = useState(""); // State for heading input
  const [contentData, setContentData] = useState(null); // State for storing saved contentData with content
  const [editIndex, setEditIndex] = useState(null); // State to track which heading is being edited
  const [renderId, setRenderId] = useState(1);
  const [isEdit, seIsEdit] = useState("");
  const [loading, setLoading] = useState(false);

  // Toggle modal visibility
  const toggleModal = () => {
    setModal(!modal);
    setSignature(!signature);
  };

  // Handle input change for heading
  const handleHeadingChange = (e) => setHeading(e.target.value);

  // Handle save - either add new or update existing heading with inboxText
  const handleSave = async () => {
    // Check if heading or inboxText is empty
    if (!heading.trim()) {
      toast.error("Heading cannot be empty!");
      return;
    }

    if (!inboxText.trim()) {
      toast.error("Content cannot be empty!");
      return;
    }

    const newHeading = {
      title: heading,
      content: inboxText,
    };
    setLoading(true);
    const Data = new FormData();
    Data.append("signature", inboxText);
    Data.append("name", heading);
    Data.append("type", 1);
    if (isEdit) {
      Data.append("id", isEdit);
    }
    const result = await postRequest("/mail/add-signature", Data);
    if (result.status) {
      setLoading(false);
    }
    toggleModal();
  };

  // Handle edit - open modal with the selected heading and inboxText
  const handleEdit = async (id) => {
    const itemToEdit = contentData.find((item) => item.id === id);
    if (itemToEdit) {
      setHeading(itemToEdit.name);
      seIsEdit(itemToEdit.id);
      setInboxText(itemToEdit.signature);
    }
    toggleModal();
  };

  // Handle delete - remove the selected heading from the list
  const handleDelete = async (id) => {
    const updatedcontentData = contentData.filter((item) => item?.id !== id);
    const data = new FormData();
    data.append("id", id);
    const response = await postRequest("/mail/delete-signature", data);
    if (response.status) {
      setContentData(updatedcontentData);
    }
  };

  // Handle editor change - update the inboxText content
  const handleEditorChange = (html) => {
    setInboxText(html);
  };

  const signatureText = async () => {
    const response = await getPostRequest("/mail/get-signature");
    setContentData(response?.data);
  };

  useEffect(() => {
    setRenderId((prev) => prev + 1);
  }, []);

  const sendDataToInbox = (text) => {
    onSendData(text);
  };

  return (
    <div>
      <>
        <UncontrolledDropdown direction="up">
          <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-tooltip" href="#toggle" onClick={signatureText}>
            <Icon name="edit-alt"></Icon>
          </DropdownToggle>
          <DropdownMenu>
            <ul className="link-list-opt no-bdr link-list-template">
              <li className="opt-head">
                <span>Signature</span>
              </li>

              {!contentData ? (
                <li>
                  <ShimmerText line={1} gap={10} />
                </li>
              ) : contentData.length > 0 ? (
                <>
                  {contentData.map((item, index) => (
                    <li key={index} onClick={() => sendDataToInbox(item?.signature)}>
                      <div className="justify-content-between d-flex px-2 pointer px-2 py-1">
                        <span>{item?.name}</span>
                        <div className="">
                          <Button
                            color="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(item?.id);
                            }}
                            className="p-0"
                          >
                            <Icon name="edit" className="fs-6" />
                          </Button>
                          <Button
                            color="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(item?.id);
                            }}
                            className="p-0 ms-1"
                          >
                            <Icon name="trash" className="fs-6" />
                          </Button>
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <div className="text-center">No signatures</div>
              )}

              <li className="divider"></li>
              <li>
                <DropdownItem
                  tag="a"
                  className="p-0 justify-content-center"
                  href="#item"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setHeading("");
                    setInboxText("");
                    setEditIndex(null);
                    toggleModal();
                  }}
                >
                  <Button color="" className="px-0">
                    <Icon name="plus" />
                    <span className="ps-0">Create new</span>
                  </Button>
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>

      {/* Modal for creating/editing signature */}
      <Modal isOpen={modal} className="add-editor" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{editIndex !== null ? "Edit Signature" : "Create New Signature"}</ModalHeader>
        <ModalBody>
          {/* Input for typing the heading */}
          <Input type="text" placeholder="Enter signature name" value={heading} onChange={handleHeadingChange} />

          <QuillEditor
            key={renderId}
            placeholder="Enter Signature Text..."
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                [{ font: [] }],
                [{ align: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ direction: "rtl" }],
                ["blockquote", "code-block"],
                ["link"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "align",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "color",
              "background",
              "link",
              "image",
              "video",
              "direction",
              "script",
              "formula",
              "clean",
            ]}
            onChange={handleEditorChange}
            value={inboxText}
            onEditor={false}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {loading ? <Spinner size="sm" /> : "Save"}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Signature;
