import React, { useContext, useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./sidebarmenu/QuillEditor.css"; // Import custom CSS
import { MyContext } from "./ContextMail";
import { Icon } from "../components/Component";
import { Spinner } from "reactstrap";
import { postRequest } from "../utils/api_helper";

const QuillEditor = ({
  placeholder = "Compose an epic...",
  theme = "snow",
  modules = {},
  formats = [],
  onChange,
  value = "",
  readOnly = false,
  customStyles = {},
  attachmentList,
  setAttachmentList,
  onEditor,
}) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const { signature } = useContext(MyContext);

  useEffect(() => {
    if (!quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme,
        placeholder,
        modules: {
          ...modules,
        },
        formats: [...formats],
        readOnly,
      });

      quillRef.current = quill;

      // Set initial content if there's a value
      if (value) {
        quill.root.innerHTML = value; // Set HTML content
      }

      quill.on("text-change", () => {
        const html = quill.root.innerHTML;
        // console.log("Text changed:", html);
        if (onChange) {
          onChange(html); // Pass full HTML content to the parent component
        }
      });
    } else {
      // Quill is already initialized, update value if necessary
      if (value !== quillRef.current.root.innerHTML) {
        quillRef.current.root.innerHTML = value; // Directly set HTML content
      }
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off("text-change");
      }
    };
  }, [theme, placeholder]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.root.innerHTML = value; // Update content when `value` changes
    }
  }, []);

  const removeAttachment = async (text) => {
    let defaultData = attachmentList;
    defaultData = defaultData.filter((item) => item.shortpath !== text);
    setAttachmentList([...defaultData]);

    const data = new FormData();
    data.append("attachment_path", text);

    try {
      const response = await postRequest("/mail/delete-attachment", data);
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const tooltips = {
    ".ql-header": "Header",
    ".ql-font": "Font",
    ".ql-align": "Align",
    ".ql-bold": "Bold",
    ".ql-italic": "Italic",
    ".ql-underline": "Underline",
    ".ql-strike": "Strike",
    ".ql-color": "Text Color",
    ".ql-background": "Background Color",
    ".ql-list[value='ordered']": "Ordered List",
    ".ql-list[value='bullet']": "Bullet List",
    ".ql-indent[value='-1']": "Decrease Indent",
    ".ql-indent[value='+1']": "Increase Indent",
    ".ql-direction": "Text Direction",
    ".ql-blockquote": "Blockquote",
    ".ql-code-block": "Code Block",
    ".ql-link": "Insert Link",
    ".ql-video": "Insert Video",
    ".ql-clean": "Remove Formatting",
  };

  useEffect(() => {
    // Add title attributes to toolbar buttons for tooltips
    Object.keys(tooltips).forEach((selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((el) => {
        el.setAttribute("title", tooltips[selector]);
      });
    });
  }, []);

  return (
    <div style={customStyles} className={`quill-editor-container ${onEditor ? "editor-tool" : ""} `}>
      <div className={`nk-reply-form-attachment ${!onEditor ? "pb-editor" : ""}`}>
        {attachmentList?.map((item, key) => (
          <a
            href={item.path}
            target="_blank"
            key={key}
            className="nk-reply-form-attachment-list p-1 align-center justify-between m-2 d-flex"
          >
            <span>{item.name}</span>
            {/* <div className="">{item.videoUrl && <video src={item.videoUrl} height="100px" controls></video>}</div>
            <div className="">{item.audioData && <audio src={item.audioData.url} controls></audio>}</div> */}
            {item.loading ? (
              <Spinner size="sm" />
            ) : (
              <a
                className="toggle-opt"
                href="remove"
                onClick={(ev) => {
                  ev.preventDefault();
                  removeAttachment(item.shortpath);
                }}
              >
                <Icon name="cross"></Icon>
              </a>
            )}
          </a>
        ))}
      </div>
      <div ref={editorRef} className="quill-editor"></div>
    </div>
  );
};

export default QuillEditor;
