import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../../components/icon/Icon";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { postRequest } from "../../../../utils/api_helper";
import { Link } from "react-router-dom";

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const handleReadNotification = (id, getNotificationData) => {
  const payload = {
    module: 9,
    notification_id: id,
    status: 1,
  };
  postRequest("/public/set-notification-status", payload).then((res) => {
    console.log("res mark as read", res);
    if (res.status === true) {
      getNotificationData();
    }
  });
};

const NotificationItem = (props) => {
  const { icon, iconStyle, text, time, id, link, getNotificationData } = props;

  return (
    <Link to={link}>
      <div
        className="nk-notification-item"
        key={id}
        id={id}
        onClick={() => handleReadNotification(id, getNotificationData)}
      >
        <div className="nk-notification-icon">
          <Icon
            name={link === "/portfolio" ? "invest" : "file-docs"}
            className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]}
          />
        </div>
        <div className="nk-notification-content">
          <div className="nk-notification-text">{text}</div>
          <div className="nk-notification-time">{formatDateTime(time)}</div>
        </div>
      </div>
    </Link>
  );
};

const Notification = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const getNotificationData = () => {
    const payload = { limit: 10, offset: 0, module: 9 };

    postRequest("/public/get-notifications", payload).then((res) => {
      // console.log("res notification", res?.data?.notifications);
      setData(res?.data?.notifications);
    });
  };

  const handleViewAll = () => {
    setOffset((prevOffset) => prevOffset + 10);
    const payload = { limit: 10, offset: offset + 10, module: 9, recent: 10 };

    postRequest("/public/get-notifications", payload).then((res) => {
      console.log("res notification", res?.data?.notifications);
      res.status === false ? setData([]) : setData((prevData) => [...prevData, ...res?.data?.notifications]);
    });
  };

  const handleMarkAllAsRead = () => {
    const payload = {
      module: 9,
      notification_id: 0,
      status: 1,
    };
    postRequest("/public/set-notification-status", payload).then((res) => {
      console.log("res mark as read", res);
      if (res.status === true) {
        setData([]);
      }
    });
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="">
          <Icon name="bell-fill" className="" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          <p style={{ cursor: "pointer" }} onClick={() => handleMarkAllAsRead()}>
            Mark All as Read
          </p>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification ">
            {data?.length === undefined && (
              <div
                style={{ textAlign: "center", marginLeft: "25%", color: "#333", fontWeight: "800" }}
                className="nk-notification-item text-center"
              >
                No new notifications
              </div>
            )}
            {data?.map((item) => (
              <NotificationItem
                key={item.id}
                id={item.id}
                icon={item.icon}
                iconStyle={item.iconStyle}
                text={item.title}
                time={item.updated_at}
                link={item.link}
                getNotificationData={getNotificationData}
              />
            ))}
          </div>
        </div>
        <div className="dropdown-foot center">
          <p style={{ cursor: "pointer" }} onClick={() => handleViewAll()}>
            {data?.length > 10 ? "View All" : ""}
          </p>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
