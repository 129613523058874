import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import InboxLayout from "../pages/app/inbox/InboxLayout";
import InboxList from "../pages/sidebarmenu/InboxList";
import DraftList from "../pages/sidebarmenu/DraftList";
import FavouriteList from "../pages/sidebarmenu/FavouriteList";
import SentList from "../pages/sidebarmenu/SentList";
import SpamList from "../pages/sidebarmenu/SpamList";
import TrashList from "../pages/sidebarmenu/TrashList";
import ArchiveList from "../pages/sidebarmenu/ArchiveList";
import AllMailList from "../pages/sidebarmenu/AllMailList";
import InboxMessages from "../pages/app/inbox/InboxMessages";
import Layout from "../layout/Index";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<InboxLayout />}>
          <Route path="" element={<InboxList />} />
          <Route path="draft" element={<DraftList />} />
          <Route path="favourite" element={<FavouriteList />} />
          <Route path="sent" element={<SentList />} />
          <Route path="spam" element={<SpamList />} />
          <Route path="trash" element={<TrashList />} />
          {/* <Route path="archive" element={<ArchiveList />} /> */}
          <Route path="all-mails" element={<AllMailList />} />
          {/* message view */}
          {/* <Route path="inbox/view/:id" element={<InboxMessages />} />
          <Route path="draft/view/:id" element={<InboxMessages />} />
          <Route path="favourite/view/:id" element={<InboxMessages />} />
          <Route path="sent/view/:id" element={<InboxMessages />} />
          <Route path="spam/view/:id" element={<InboxMessages />} />
          <Route path="trash/view/:id" element={<InboxMessages />} />
          <Route path="draft/view/:id" element={<InboxMessages />} /> */}
        </Route>
      </Route>
    </Routes>
  );
};
export default Pages;
