import React from "react";
import { ShimmerThumbnail, ShimmerText, ShimmerCircularImage } from "react-shimmer-effects";

const ShimmerMailList = () => {
  return (
    <>
      <div style={{ padding: "10px" }}>
        {Array(4)
          .fill("")
          .map((_, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                padding: "10px",
                borderBottom: "1px solid #ddd",
              }}
            >
              {/* Checkbox Shimmer */}
              <div style={{ marginRight: "15px" }}>
                <div className="shimmer-checkout">
                  <ShimmerThumbnail height={25} width={1} rounded />
                </div>
              </div>

              {/* Star Shimmer */}
              <div style={{ marginRight: "15px" }}>
                <div className="star-shimmer">
                  <ShimmerThumbnail height={30} borderRadius={4} />
                </div>
              </div>

              {/* Profile Image Shimmer */}
              <div style={{ marginRight: "15px" }}>
                <ShimmerCircularImage size={40} />
              </div>

              {/* Content Shimmer */}
              <div style={{ flex: 1 }}>
                <ShimmerText line={2} gap={10} />
              </div>

              {/* Time Shimmer */}
              <div style={{ marginLeft: "15px", width: "50px" }}>
                <ShimmerText line={1} gap={5} />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ShimmerMailList;
