import React, { useState, createContext, useEffect } from "react";
import { fetchGet } from "../utils/api_helper";

export const MyContext = createContext();

export const ContextMailProvider = ({ children }) => {
  const [checkMail, setCheckMail] = useState([]);
  const [onEditor, setOnEditor] = useState(true);

  // const [checkHead, setCheckHead] = useState(false);
  const [deleteMail, setDeleteMail] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [signature, setSignature] = useState(true);
  const [storageFiledAlert, setStorageFiledAlert] = useState(false);
  const [countRefresh, setCountRefresh] = useState(false);
  const [attachmentList, setAttachmentList] = useState([]);
  const [messageView, setMessageView] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const toggleMessageView = () => {
    if (messageView) {
      fetchTabData();
      setRefresh(true);
    }
    setMessageView(!messageView);
  };

  const [recentDelete, setRecentDelete] = useState(null);
  const [recentRead, setRecentRead] = useState(null);

  const [draftComposeData, setDraftComposeData] = useState(null);
  const [draftComposeModal, setDraftComposeModal] = useState(false);

  const openDraftCompose = (item) => {
    setDraftComposeData(item);
    setDraftComposeModal(true);
  };

  const closeDraftCompose = () => {
    setDraftComposeData(null);
    setDraftComposeModal(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("msg-id");
    if (id) {
      setSelectedId(id);
      setMessageView(true);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (messageView && selectedId) {
      // Add or update msg-id query parameter
      urlParams.set("msg-id", selectedId);
      // Construct the new URL with updated query parameters
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    } else {
      // Remove msg-id query parameter
      urlParams.delete("msg-id");
      // Construct the new URL with updated query parameters
      const newUrl = `${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [messageView, selectedId]);

  const [tabCounts, setTabCounts] = useState({
    Inbox: 0,
    Draft: 0,
    Trash: 0,
    Spam: 0,
  });

  const fetchTabData = async () => {
    const response = await fetchGet("/mail/show-labels-count");
    if (response?.status) {
      setTabCounts({
        Inbox: response.inbox_count,
        Draft: response.draft_count,
        Trash: response.trash_count,
        Spam: response.spam_count,
      });
    }
  };
  const onEditorTool = () => {
    setOnEditor(!onEditor);
  };

  useEffect(() => {
    fetchTabData();
  }, [countRefresh]);

  return (
    <MyContext.Provider
      value={{
        onEditor,
        setOnEditor,
        checkMail,
        setCheckMail,
        deleteMail,
        setDeleteMail,
        refresh,
        setRefresh,
        messageView,
        setMessageView,
        toggleMessageView,
        selectedId,
        setSelectedId,
        openDraftCompose,
        closeDraftCompose,
        draftComposeData,
        draftComposeModal,
        setDraftComposeModal,
        recentDelete,
        setRecentDelete,
        recentRead,
        setRecentRead,
        countRefresh,
        setCountRefresh,
        tabCounts,
        fetchTabData,
        attachmentList,
        setAttachmentList,
        onEditorTool,
        signature,
        setSignature,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};
