import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { formTemplates } from "./InboxData";
import { Icon, Button, TooltipComponent } from "../../../components/Component";
import { Modal, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { getDateStructured, currentTime, getCookie, safeJsonParse } from "../../../utils/Utils";
import VideoRecorder from "../../components/VideoRecorder";
import AudioRecorder from "../../components/AudioRecorder";
import trash from "../../../images/applogos/images/trash.png";
import image from "../../../images/applogos/images/image.png";
import attache from "../../../images/applogos/images/attach.png";
import audio from "../../../images/applogos/images/audio.png";
import video from "../../../images/applogos/images/video.png";
import formatEditor from "../../../images/applogos/images/formatEditor.png";
import { fetchGet, postRequest } from "../../../utils/api_helper";
import { toast } from "react-toastify";
import { createGlobalStyle } from "styled-components";
import { MyContext } from "../../ContextMail";
import { Link } from "react-router-dom";
import QuillEditor from "../../QuillEditor";
import debounce from "lodash.debounce";
import Signature from "../../Signature";
import QuickInsert from "../../QuickInsert";

const tagifySettings = {
  // blacklist: ["xxx", "yyy", "zzz"],
  // maxTags: 6,
  backspace: "edit",
  addTagOnBlur: true,
  placeholder: "",
  dropdown: {
    classname: "color-blue",
    enabled: 1,
    // maxItems: 8,
    position: "text", // place the dropdown near the typed text
    closeOnSelect: true, // keep the dropdown open after selecting a suggestion
    highlightFirst: true,
  },
};

const stripHtmlTags = (html) => {
  return html
    .replace(/<\/?[^>]+(>|$)/g, " ")
    .replace(/\s+/g, " ")
    .trim();
};

const InboxForm = ({
  mailData,
  setMailData,
  currentItem,
  deleteInbox,
  editOnOpen,
  composeState,
  toggleModal,
  composeMail,
  draftData,
}) => {
  const [suggestionMails, setSuggestionMails] = useState([]);
  // console.log(draftData);
  const [inboxText, setInboxText] = useState("");
  const [inboxSubject, setInboxSubject] = useState("");
  const [templateList, setTemplateList] = useState(formTemplates);

  const [modalXl, setModalXl] = useState(false);

  const [attachmentList, setAttachmentList] = useState([]);
  const [onEditor, setOnEditor] = useState(true);
  const onEditorTool = () => {
    setOnEditor(!onEditor);
  };

  const [uploadPromise, setUploadPromise] = useState(null);

  const { setRefresh, fetchTabData, closeDraftCompose, signature, setDraftComposeModal, openDraftCompose } =
    useContext(MyContext);
  const [typedText, setTypedText] = useState(""); // For manually typed text
  const [draftId, setDraftId] = useState(null);
  const [renderId, setRenderId] = useState(1);

  const [tagifyOptions, setTagifyOptions] = useState({
    toggle: true,
    value: [""],
  });
  const [invalidTo, setInvalidTo] = useState(true);

  const [ccTagify, setCCTagify] = useState({
    toggle: false,
    value: [""],
  });
  const [invalidCC, setInvalidCC] = useState(true);

  const [bccTagify, setBCCTagify] = useState({
    toggle: false,
    value: [""],
  });
  const [invalidBCC, setInvalidBCC] = useState(true);

  // const [storeVideo, setStoreVideo] = useState([]);

  // useEffect(() => {
  //   console.log(attachmentList, "attachments");
  // }, [attachmentList]);

  const handleAudioRecorded2 = (audioData) => {
    console.log(audioData);
    setAttachmentList([...attachmentList, { audioData }]);
    // setStoreVideo([...storeVideo, videoUrl]);
  };

  useEffect(() => {
    if (draftData) {
      // console.log(draftData);
      setDraftId(draftData.id);
      setRenderId((prev) => prev + 1);
      setInboxSubject(draftData.message.subject || "");
      setInboxText(draftData.message.reply[0].replyMessage || "");
      setAttachmentList(draftData.message.reply[0].attachment || "");

      const recipients = draftData.message.reply[0].to;

      // Handle "to" recipients
      if (recipients?.recipient) {
        const toRecipients = recipients.recipient.map(({ mail }) => ({
          value: mail,
        }));
        setTagifyOptions({
          toggle: true,
          value: toRecipients,
        });
        setInvalidTo(false);
      }

      // Handle "cc" recipients
      if (recipients?.cc?.length > 0) {
        const ccRecipients = recipients.cc.map(({ mail }) => ({
          value: mail,
        }));
        setCCTagify({
          toggle: true,
          value: ccRecipients,
        });
        setInvalidCC(false);
      }

      // Handle "bcc" recipients
      if (recipients?.bcc?.length > 0) {
        const bccRecipients = recipients.bcc.map(({ mail }) => ({
          value: mail,
        }));
        setBCCTagify({
          toggle: true,
          value: bccRecipients,
        });
        setInvalidBCC(false);
      }
    }
  }, [draftData]);

  const checkDraftable = () => {
    // Check if inboxSubject or inboxText have data
    const hasSubject = inboxSubject?.trim() !== "";
    const hasText = inboxText?.trim() !== "";

    // Check if there are any valid recipients in mail, cc, or bcc
    const hasMailRecipients = tagifyOptions?.value?.some(
      (item) => typeof item.value === "string" && item.value.trim() !== ""
    );
    const hasCcRecipients = ccTagify?.value?.some((item) => typeof item.value === "string" && item.value.trim() !== "");
    const hasBccRecipients = bccTagify?.value?.some(
      (item) => typeof item.value === "string" && item.value.trim() !== ""
    );

    // Check if all recipient lists are invalid
    const recipients = invalidTo ? [] : tagifyOptions.value.map((item) => item.value);
    const cc = invalidCC ? [] : ccTagify.value.map((item) => item.value);
    const bcc = invalidBCC ? [] : bccTagify.value.map((item) => item.value);

    const allRecipientsInvalid = !recipients.length && !cc.length && !bcc.length;

    // Check if there are any attachments
    const hasAttachments = attachmentList.length > 0;

    // Return true if there is any data in subject, text, or attachments
    // OR if there are valid recipients
    return (
      hasSubject ||
      hasText ||
      hasAttachments ||
      (!allRecipientsInvalid && (hasMailRecipients || hasCcRecipients || hasBccRecipients))
    );
  };

  const sendInbox = async () => {
    if (invalidBCC && invalidCC && invalidTo) {
      toast.warn("Please add a recipient.");
      return;
    }
    try {
      if (uploadPromise) {
        try {
          await toast.promise(uploadPromise, {
            pending: "Uploading files, please wait...",
            success: "Files uploaded! Ready to send.",
          });
        } catch (error) {
        } finally {
          setUploadPromise(null);
          return;
        }
      }
      await sendMail("sent");
    } catch (error) {}
  };

  const sendDraft = async () => {
    if (checkDraftable()) {
      if (uploadPromise) {
        console.log(uploadPromise);
        try {
          await toast.promise(uploadPromise, {
            pending: "Uploading files, please wait...",
            success: "Files uploaded! Ready to save.",
          });
        } catch (error) {
        } finally {
          setUploadPromise(null);
          return;
        }
      }

      await sendMail("draft");
    } else {
      closeModal();
    }
  };

  const sendMail = async (type, isFail = false) => {
    // Gather all the necessary data first
    const recipients = invalidTo ? "" : tagifyOptions.value.map((item) => item.value);
    const cc = invalidCC ? "" : ccTagify.value.map((item) => item.value);
    const bcc = invalidBCC ? "" : bccTagify.value.map((item) => item.value);

    const data = new FormData();
    if (draftId) {
      data.append("id", draftId);
    }
    data.append("sender", getCookie("email"));
    data.append("recipients", recipients);
    data.append("cc", cc);
    data.append("bcc", bcc);
    data.append("subject", inboxSubject);
    data.append("message", inboxText);
    data.append("attachment", JSON.stringify(attachmentList));
    data.append("is_draft", type === "sent" ? 0 : 1);

    // Close modal after gathering data but before making the request
    // closeModal();

    toggleModal(false);
    setDraftComposeModal(false);

    const toastMessages = {
      pending: type === "sent" ? "Sending email, please wait..." : "Saving draft, please wait...",
      success: type === "sent" ? "Email has been sent successfully." : "Draft has been saved successfully.",
      error: type === "sent" ? "Email sending failed, saving draft..." : "Unable to save the draft.",
      autoClose: 5000,
    };

    // Using toast.promise for async call
    const sendPromise = postRequest("/mail/send-mail", data);

    toast.promise(sendPromise, toastMessages);

    try {
      const result = await sendPromise;
      if (result.status) {
        closeModal();
        if (isFail) {
          toggleModal(true);
          setDraftComposeModal(true);
          openLatestSavedDraft();
        }
      }
    } catch (err) {
      if (type === "sent") await sendMail("draft", true);
      else {
        // console.log(err);
        toggleModal(true);
        setDraftComposeModal(true);
      }
    } finally {
      fetchTabData();
      setRefresh(true);
    }
  };

  // const validateEmail = (email, e) => {
  //   // console.log(email, e);
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   // console.log(emailRegex.test(email));
  //   return emailRegex.test(email);
  // };

  // const beforeAddTag = (e) => {
  //   const email = e.detail.data.value;
  //   // console.log(e);

  //   // if (!validateEmail(email)) {
  //   //   // Prevent the invalid email from being added as a tag
  //   //   e.preventDefault();
  //   //   toast.error("Please enter a valid email address.");
  //   // }
  // };

  const onTagifyChange = (e) => {
    // console.log(e);
    try {
      let arrayVal = JSON.parse(e.detail.value);
      setTagifyOptions({ toggle: true, value: arrayVal });
      setInvalidTo(false);
    } catch (error) {
      // console.error("Failed to parse JSON or handle tagify options:", error);
      if (e.detail.value === "") {
        setInvalidTo(true);
      }
    }
  };

  const onCcChange = (e) => {
    try {
      let arrayVal = JSON.parse(e.detail.value);
      setCCTagify({ toggle: true, value: arrayVal });
      setInvalidCC(false);
    } catch (error) {
      // console.error("Failed to parse JSON or handle CC tagify options:", error);
      if (e.detail.value === "") {
        setInvalidCC(true);
      }
    }
  };

  const onBccChange = (e) => {
    try {
      let arrayVal = JSON.parse(e.detail.value);
      setBCCTagify({ toggle: true, value: arrayVal });
      setInvalidBCC(false);
    } catch (error) {
      // console.error("Failed to parse JSON or handle BCC tagify options:", error);
      if (e.detail.value === "") {
        setInvalidBCC(true);
      }
    }
  };

  const saveTemplate = () => {
    if (inboxText !== "") {
      let defaultList = templateList;
      let newObject = {
        id: defaultList.length + 1,
        text: inboxText,
      };
      setTemplateList([...defaultList, newObject]);
      setInboxText("");
    }
  };

  const onAttachmentClick = () => {
    const inputEl = document.getElementById("attachmentInput");
    inputEl.click();
    inputEl.onchange = onAttachmentChange;
  };

  const onAttachmentChange = async (ev) => {
    if (ev.target.files.length > 0) {
      const files = Array.from(ev.target.files); // Convert FileList to an array
      // Add files to the state with loading: true
      const tempFiles = files.map((file) => ({
        name: file.name,
        size: file.size,
        shortpath: file.name,
        path: URL.createObjectURL(file),
        loading: true, // Temporarily mark as loading
      }));

      setAttachmentList((prevFiles) => [...prevFiles, ...tempFiles]); // Add to the state

      const upload = uploadFiles(files, tempFiles);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const onImageClick = () => {
    const inputEl = document.getElementById("imageInput");
    inputEl.click();
    inputEl.onchange = onImageChange;
  };

  const onImageChange = async (ev) => {
    if (ev.target.files.length > 0) {
      const files = Array.from(ev.target.files); // Convert FileList to an array
      // Add files to the state with loading: true
      const tempFiles = files.map((file) => ({
        name: file.name,
        size: file.size,
        shortpath: file.name,
        path: URL.createObjectURL(file),
        loading: true, // Temporarily mark as loading
      }));

      setAttachmentList((prevFiles) => [...prevFiles, ...tempFiles]); // Add to the state

      const upload = uploadFiles(files, tempFiles);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const handleVideoRecorded = async (blob) => {
    if (blob) {
      // Create a temporary file-like object for the video blob
      const videoFile = new File([blob], "recorded-video.webm", { type: blob.type });

      // Create a temp file object similar to how you're doing it with ev.target.files
      const tempFile = {
        name: videoFile.name,
        size: videoFile.size,
        shortpath: videoFile.name,
        path: URL.createObjectURL(videoFile), // Create an object URL for the blob
        loading: true, // Mark as loading temporarily
      };

      // Add the video to the attachment list
      setAttachmentList((prevFiles) => [...prevFiles, tempFile]);

      // Now handle the file upload process if needed
      const upload = uploadFiles([videoFile], [tempFile]);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const handleAudioRecorded = async (blob) => {
    if (blob) {
      // Create a temporary file-like object for the audio blob
      const audioFile = new File([blob.blob], "recorded-audio.wav", { type: blob.blob.type });

      // Create a temp file object similar to how you're doing it with ev.target.files
      const tempFile = {
        name: audioFile.name,
        size: audioFile.size,
        shortpath: audioFile.name,
        path: URL.createObjectURL(audioFile), // Create an object URL for the blob
        loading: true, // Mark as loading temporarily
      };

      // Add the audio to the attachment list
      setAttachmentList((prevFiles) => [...prevFiles, tempFile]);

      // Now handle the file upload process if needed
      const upload = uploadFiles([audioFile], [tempFile]);
      setUploadPromise(upload);
      try {
        await upload;
      } catch (error) {
      } finally {
        setUploadPromise(null);
      }
    }
  };

  const onDeleteClick = async () => {
    closeModal();

    if (draftId) {
      try {
        const fdata = new FormData();
        fdata.append("email_id", draftId);
        const deletePromise = postRequest("/mail/delete-email", fdata);

        toast.promise(deletePromise, {
          pending: "Deleting draft...",
          success: {
            render({ data }) {
              setRefresh(true);
              fetchTabData();
              const undoMessageSeen = async () => {
                const undoData = new FormData();
                undoData.append("email_id", draftId);
                const response = await postRequest("/mail/undo-trash-email", undoData);

                if (response.status) {
                  setRefresh(true);
                  fetchTabData();
                  toast.success("Draft restored successfully!", { autoClose: 3000 });
                } else {
                  toast.error("Failed to restore the draft.", { autoClose: 3000 });
                }
              };

              return (
                <div>
                  <span>{data.message || "Draft discarded!"}</span>
                  <Link
                    to=""
                    className="ms-3"
                    onClick={(e) => {
                      e.preventDefault();
                      undoMessageSeen();
                    }} // Attach the function here
                  >
                    Undo
                  </Link>
                </div>
              );
            },
            autoClose: 5000,
          },
          error: "Failed to delete email.",
        });
      } catch (error) {
        console.log("delete mail", error);
        toast.error("An error occurred while deleting the email.");
      }
    }
  };

  const uploadFiles = (files, tempFiles) => {
    return new Promise(async (resolve, reject) => {
      const data = new FormData();

      // Append each selected file to FormData
      files.forEach((file) => {
        data.append("attachment[]", file); // 'attachment[]' for multiple file upload
      });

      try {
        // Send the FormData with all the selected files
        const response = await postRequest("/mail/upload-attachment", data);

        // Check if the response is successful
        if (response.status) {
          const uploadedFilesData = response.data.map((file) => ({
            name: file.fileName, // Replace fileName with name
            size: file.size,
            shortpath: file.shortpath,
            path: file.path, // Use the provided file path
          }));

          // Replace the temporary files in the state with the actual uploaded file data
          setAttachmentList((prevFiles) => {
            // Remove temporary files that were marked as loading
            const filteredFiles = prevFiles.filter((file) => !tempFiles.includes(file));

            // Return the previously uploaded files + new files from the API response
            return [...filteredFiles, ...uploadedFilesData];
          });

          // Resolve the promise with success data
          resolve(uploadedFilesData);
        } else {
          throw new Error("Failed to upload files");
        }
      } catch (error) {
        // console.error("Error uploading files:", error);

        // Show error toast message
        toast.error("Failed to upload files. Please try again.");

        // Remove the temporary file objects marked as loading
        setAttachmentList((prevFiles) => prevFiles.filter((file) => !tempFiles.includes(file)));

        // Reject the promise with an error
        reject(error);
      }
    });
  };

  const settings = {
    ...tagifySettings,
    addTagOnBlur: true,
    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
    // validate: (e) => {
    //   return validateEmail(e.value, e);
    // },
    duplicate: false,
    skipInvalid: false,
    keepInvalidTags: true,
    createInvalidTags: true,
    // callbacks: {
    //   add: beforeAddTag,
    // },
    editTags: {
      keepInvalid: false,
    },
  };

  // for video and audio recorder
  const [messageState, setMessageState] = useState(false);
  let [recordOption, setRecordOption] = useState(null);

  const toggleRecordOption = (type) => {
    setRecordOption(type);
  };

  const toggleMessageModal = () => {
    setMessageState(!composeState);
    // setRecordOption(null);
  };

  function closeModal() {
    setTagifyOptions({
      toggle: false,
      value: [""],
    });
    setCCTagify({
      toggle: false,
      value: [""],
    });
    setBCCTagify({
      toggle: false,
      value: [""],
    });
    setInvalidTo(true);
    setInvalidCC(true);
    setInvalidBCC(true);
    setInboxSubject("");
    setInboxText("");
    setAttachmentList([]);
    setOnEditor(true);

    toggleModal(false);
    setDraftId(null);
    closeDraftCompose();
  }

  const handleEditorChange = (html) => {
    // console.log("Editor content received:", html); // Log the full content
    setInboxText(html);
    // setTypedText(html);
  };

  const modalView = () => {
    setModalXl(!modalXl);
  };

  const allSuggestions = async () => {
    const fdata = new FormData();
    fdata.append("suggestion", "@");
    try {
      const response = await postRequest("/mail/get-suggestions", fdata);
      setSuggestionMails(response.data); // Update suggestions based on API response
    } catch (error) {
      // console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    allSuggestions();
  }, []);

  const addSignature = (text) => {
    setRenderId((prev) => prev + 1);
    setInboxText((prev) => prev + "<br/>--" + text);
  };

  const addQuickInsert = (text) => {
    setRenderId((prev) => prev + 1);
    setInboxText((prev) => prev + "" + text);
  };

  const openLatestSavedDraft = async () => {
    try {
      const response = await fetchGet("/mail/get-draft-email");
      if (response.status) {
        if (response?.data?.length > 0) {
          openDraftCompose(response.data[0]);
        }
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  return (
    <>
      <Modal
        isOpen={composeState}
        toggle={() => {
          sendDraft();
        }}
        className={`send-mail ${modalXl ? "modal-extra-large" : "modal-lg"}`}
      >
        <div className="modal-header">
          <h6 className="modal-title">New Message</h6>
          <div className="d-flex align-items-center gap-1">
            {modalXl ? (
              <Icon name="minimize" className="fs-5 pointer" onClick={modalView}></Icon>
            ) : (
              <Icon name="maximize" className="fs-5 pointer" onClick={modalView}></Icon>
            )}
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                // addToDraft();
                sendDraft();
                // closeModal();
              }}
              className="close"
            >
              <Icon name="cross-sm" className="fs-2"></Icon>
            </a>
          </div>
        </div>
        <div className="modal-body p-0">
          <div className="nk-reply-form-header">
            <div className="nk-reply-form-group">
              <div className="nk-reply-form-title d-sm-none">Reply</div>
              <div className="nk-reply-form-input-group">
                <div className="nk-reply-form-input nk-reply-form-input-to">
                  <label className="label">To</label>
                  <Tags
                    whitelist={suggestionMails}
                    className="input-mail"
                    mode="textarea"
                    value={tagifyOptions?.value}
                    onChange={(e) => onTagifyChange(e)}
                    // onInput={getSuggestion}
                    settings={settings}
                    showDropdown={true}
                  />
                </div>
                <div className={`nk-reply-form-input nk-reply-form-input-cc ${ccTagify.toggle ? "expanded" : ""} mt-1`}>
                  <label className="label">CC</label>
                  <Tags
                    whitelist={suggestionMails}
                    className="input-mail"
                    mode="textarea"
                    value={ccTagify?.value}
                    onChange={(e) => onCcChange(e)}
                    settings={settings}
                    showDropdown={false}
                  />
                  {/* <input
                    type="text"
                    className="input-mail"
                    value={ccTagify.value}
                    onChange={(e) => onCcChange(e)}
                    placeholder="Enter tags here..."
                  /> */}
                  <a
                    className="toggle-opt active"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      // setCCTagify({ ...ccTagify, toggle: !ccTagify.toggle });
                      setCCTagify({ value: [""], toggle: false });
                    }}
                  >
                    <Icon name="cross"></Icon>
                  </a>
                </div>
                <div
                  className={`nk-reply-form-input nk-reply-form-input-bcc ${bccTagify.toggle ? "expanded" : ""} mt-1`}
                >
                  <label className="label">BCC</label>
                  <Tags
                    whitelist={suggestionMails}
                    className="input-mail"
                    mode="textarea"
                    value={bccTagify?.value}
                    onChange={(e) => onBccChange(e)}
                    settings={settings}
                    showDropdown={false}
                  />
                  <a
                    className="toggle-opt active"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      // setBCCTagify({ ...bccTagify, toggle: !bccTagify.toggle });
                      setBCCTagify({ value: [""], toggle: false });
                    }}
                  >
                    <Icon name="cross"></Icon>
                  </a>
                </div>
              </div>
              <ul className="nk-reply-form-nav">
                <li>
                  <a
                    className="toggle-opt"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      // setCCTagify({ ...ccTagify, toggle: !ccTagify.toggle });
                      setCCTagify({ ...ccTagify, toggle: true });
                    }}
                  >
                    CC
                  </a>
                </li>
                <li>
                  <a
                    className="toggle-opt"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      // setBCCTagify({ ...bccTagify, toggle: !bccTagify.toggle });
                      setBCCTagify({ ...bccTagify, toggle: true });
                    }}
                  >
                    BCC
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="nk-reply-form-editor">
            <div className="nk-reply-form-field">
              <input
                type="text"
                className="form-control form-control-simple"
                placeholder="Subject"
                value={inboxSubject}
                onChange={(e) => setInboxSubject(e.target.value)}
              />
            </div>
            <div className="nk-reply-form-field p-0 position-relative">
              <QuillEditor
                key={renderId}
                placeholder="Enter Message..."
                theme="snow"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ color: [] }, { background: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    ["blockquote", "code-block"],
                    ["link", "video"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "size",
                  "align",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "code-block",
                  "list",
                  "bullet",
                  "indent",
                  "color",
                  "background",
                  "link",
                  "image",
                  "video",
                  "direction",
                  "script",
                  "formula",
                  "clean",
                ]}
                onChange={handleEditorChange} // Pass the handler directly
                value={inboxText} // Pass the current content from state to Quill
                attachmentList={attachmentList}
                setAttachmentList={setAttachmentList}
                onEditor={onEditor}
              />
            </div>
          </div>

          <div className="nk-reply-form-tools">
            <ul className="nk-reply-form-actions g-1">
              <li className="me-2">
                <Button color="primary" type="submit" onClick={() => sendInbox()}>
                  Send
                </Button>
              </li>
              <li>
                <QuickInsert onSendMessage={addQuickInsert} />
              </li>
              {/* <li>
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    className="btn btn-icon btn-sm btn-tooltip"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                  >
                    <Icon name="hash"></Icon>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr link-list-template">
                      <li className="opt-head">
                        <span>Quick Insert</span>
                      </li>
                      {templateList.map((item) => (
                        <li key={item.id}>
                          <DropdownItem
                            href="#item"
                            tag="a"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setRenderId((prev) => prev + 1);
                              setInboxText(inboxText + item.text);
                            }}
                          >
                            <span>{stripHtmlTags(item.text)}</span>
                          </DropdownItem>
                        </li>
                      ))}
                      <li className="divider"></li>
                      <li onClick={() => saveTemplate()}>
                        <DropdownItem tag="a" href="#item" onClick={(ev) => ev.preventDefault()}>
                          <Icon name="file-plus"></Icon>
                          <span>Save as Template</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li> */}
              <li onClick={() => onAttachmentClick()}>
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-sm"
                  // icon="clip-v"
                  path={attache}
                  id="ibx-msg-attachment"
                  direction="top"
                  text="Upload Attachment"
                />
                <input type="file" id="attachmentInput" multiple style={{ display: "none" }}></input>
              </li>
              <li onClick={() => onImageClick()}>
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-sm"
                  // icon="img"
                  path={image}
                  id="ibx-msg-images"
                  direction="top"
                  text="Upload Images"
                />
                <input
                  type="file"
                  id="imageInput"
                  accept=".png, .jpg, .jpeg, .gif, .bmp, .svg, .webp, .tiff" // Support various image formats
                  multiple // Allow selecting multiple files
                  style={{ display: "none" }}
                />
              </li>

              {/* <li className="d-none"> */}
              <li>
                <div
                  onClick={() => {
                    toggleRecordOption("video");
                    setMessageState(true);
                  }}
                >
                  <TooltipComponent
                    tag="a"
                    containerClassName="btn btn-icon btn-sm"
                    // icon="video"
                    path={video}
                    id="ibx-video-message"
                    direction="top"
                    text="Record Video"
                  />
                </div>
              </li>

              <li>
                {/* <li className="d-none"> */}
                <div
                  onClick={() => {
                    toggleRecordOption("audio");
                    setMessageState(true);
                  }}
                >
                  <TooltipComponent
                    tag="a"
                    containerClassName="btn btn-icon btn-sm"
                    icon="mic"
                    path={audio}
                    id="ibx-audio-message"
                    direction="top"
                    text="Record Audio"
                  />
                </div>
              </li>

              <li className="">
                <Signature onSendData={addSignature} />
              </li>

              <li onClick={() => onEditorTool()}>
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-sm"
                  // icon="img"
                  path={formatEditor}
                  id="editor"
                  direction="top"
                  text="Formating options"
                />
              </li>
            </ul>

            <ul className="nk-reply-form-actions g-1">
              <li onClick={() => onDeleteClick()}>
                <TooltipComponent
                  tag="a"
                  containerClassName="btn-trigger btn btn-icon"
                  // icon="trash"
                  path={trash}
                  id="ibx-msg-dlt"
                  direction="top"
                  text="Delete"
                />
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal isOpen={messageState} className="modal-lg" togglemessage={() => toggleMessageModal()}>
        <div className="modal-header">
          <h6 className="modal-title">{recordOption === "video" ? "Video Recorder" : "Audio Recorder"}</h6>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              // addToDraft();
              toggleMessageModal();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
        </div>
        <div className="modal-body p-5">
          {recordOption && (
            <div className="">
              {recordOption === "video" ? (
                <VideoRecorder
                  onVideoRecorded={handleVideoRecorded}
                  toggleMessageModal={toggleMessageModal}
                  isModalOpen={messageState}
                />
              ) : recordOption === "audio" ? (
                <AudioRecorder
                  onAudioRecorded={handleAudioRecorded}
                  toggleMessageModal={toggleMessageModal}
                  isModalOpen={messageState}
                />
              ) : null}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default InboxForm;
