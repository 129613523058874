import React, { useContext, useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon } from "../../../components/Component";
import archive from "../../../images/applogos/images/archive.png";
import refreshimg from "../../../images/applogos/images/refresh.png";
import reply from "../../../images/applogos/images/reply.png";
import search from "../../../images/applogos/images/search.png";
import trash from "../../../images/applogos/images/trash.png";
import MarkAsRead from "../../../images/applogos/images/mark-as-read.png";
import MarkAsUnRead from "../../../images/applogos/images/mark-as-unread.png";
import { MyContext } from "../../ContextMail";
import { deleteRequestMail, postRequest } from "../../../utils/api_helper";
import { toast } from "react-toastify";
import { GloabalContext } from "../../../layout/Index";
import { Link, useLocation } from "react-router-dom";

const InboxBodyHead = ({
  // allData,
  aside,
  setAside,
  data,
  setData,
  currentTab,
  selectorArchive,
  itemPerPage,
  totalItems,
  paginate,
  currentPage,
}) => {
  const {
    checkHead,
    setCheckHead,
    deleteMail,
    checkMail,
    setCheckMail,
    refresh,
    setRefresh,
    countRefresh,
    setCountRefresh,
    fetchTabData,
  } = useContext(MyContext);
  const { toastVisible, setToastVisible, showMessage, setShowMessage, showToast } = useContext(GloabalContext);
  const [searchText, setSearchText] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [showRead, setShowRead] = useState(false);

  // useEffect(() => {
  //   if (searchText !== "") {
  //     const filteredData = data.filter(
  //       (item) =>
  //         item.name.toLowerCase().includes(searchText.toLowerCase()) ||
  //         item.message.subject.toLowerCase().includes(searchText.toLowerCase())
  //     );
  //     setData(filteredData);
  //   } else {
  //     setData(data);
  //   }
  // }, [searchText]);

  const onSearchChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };

  const toggleSearch = () => {
    setSearchToggle(!searchToggle);
  };

  const location = useLocation();
  const isOnTrashPage = location.pathname === "/trash";

  // const selectorDeleteInbox = async () => {
  //   const oldData = data;
  //   let updatedData = data.filter((item) => {
  //     return !checkMail.includes(item.id);
  //   });
  //   setData(updatedData);
  //   try {
  //     const data = new FormData();
  //     // data.append("checked_id", checkMail);
  //     data.append("checked_id", checkMail);
  //     const response = await postRequest("/mail/delete-list", data);

  //     if (response.status) {
  //       const undoMessageSeen = async () => {
  //         const data = new FormData();
  //         data.append("email_id", checkMail);
  //         const response = await postRequest("/silomail/undo-trash-email", data);
  //         if (response.status) {
  //           setData(oldData);
  //         }
  //       };
  //       showToast(response.message, "Undo", undoMessageSeen, 5000);
  //     } else {
  //       setShowMessage(response.message);
  //     }
  //   } catch (error) {
  //     console.log("delete mail", error);
  //   }
  // };
  // Initialize all new mails as unread when fetching data
  useEffect(() => {
    if (Array.isArray(checkMail) && Array.isArray(data)) {
      // Ensure all new mails are marked as unread by default
      const initializedData = data.map((mailItem) => {
        if (mailItem.message && mailItem.message.meta && mailItem.message.meta.read === undefined) {
          // If read status is undefined, set it to false (unread)
          mailItem.message.meta.read = false;
        }
        return mailItem;
      });

      // Update the data state with the initialized mails
      setData(initializedData);

      if (checkMail.length === 1) {
        // Single mail selected
        const mailItem = initializedData.find((item) => item.id === checkMail[0]);
        setShowRead(mailItem ? !mailItem.message.meta.read : false); // Set opposite of the current read status
      } else if (checkMail.length > 1) {
        // Multiple mails selected
        const anyUnread = checkMail.some((id) => {
          const mailItem = initializedData.find((item) => item.id === id);
          return mailItem && !mailItem.message.meta.read;
        });
        setShowRead(anyUnread);
      }
    } else {
      setShowRead(false); // Default behavior
    }
  }, [checkMail, data]);

  const seMarkAsRead = async () => {
    let updatedData = data.map((item) => {
      if (checkMail.includes(item.id)) {
        // Toggle the read status based on current state
        item.message.meta.read = showRead ? true : false;
      }
      return item;
    });

    // Update the state with the modified data
    setData(updatedData);

    try {
      const formData = new FormData();
      formData.append("read_id", checkMail);
      formData.append("action", showRead ? "read" : "unread");
      const response = await postRequest("/mail/read-email", formData);
      if (response.status) fetchTabData();
    } catch (error) {
      console.log("Error updating mail read status", error);
    }
  };

  const undoTrash = async () => {
    let updatedData = data.filter((item) => !checkMail.includes(item.id));

    // Prepare the undo data
    const undoData = new FormData();
    undoData.append("email_id", checkMail);

    // Use toast.promise to handle the API call and show notifications
    toast.promise(postRequest("/mail/undo-trash-email", undoData), {
      pending: "Restoring emails...",
      success: {
        render({ data }) {
          // On success, update the state and refresh count
          if (data.status) {
            setCountRefresh((prev) => prev + 1); // Use `prev + 1` to increment
            setData(updatedData);
            toggleRefresh();
            return "Emails restored successfully!";
          }
          throw new Error("Unexpected error"); // Fallback in case of unexpected behavior
        },
        autoClose: 3000,
      },
      error: {
        render({ data }) {
          // Revert the data if the request fails
          setData(data);
          return "Failed to restore emails.";
        },
        autoClose: 3000,
      },
    });
  };

  const selectorDeleteInbox = async () => {
    if (!checkMail.length > 0) {
      return;
    }
    const oldData = data;

    try {
      const fdata = new FormData();
      fdata.append("checked_id", checkMail);

      // Display a promise toast that shows the pending, success, and error states
      const deletePromise = postRequest("/mail/delete-list", fdata);

      toast.promise(deletePromise, {
        pending: "Deleting emails...",
        success: {
          render({ data }) {
            fetchTabData();
            // On success, filter the data and set the new state
            let updatedData = oldData.filter((item) => {
              return !checkMail.includes(item.id);
            });
            setData(updatedData);

            // Define the undo function
            const undoMessageSeen = async () => {
              const undoData = new FormData();
              undoData.append("email_id", checkMail);
              const response = await postRequest("/mail/undo-trash-email", undoData);

              if (response.status) {
                setCountRefresh((prev) => prev++);
                setData(oldData);
                toast.success("Email restored successfully!", { autoClose: 3000 });
              } else {
                toast.error("Failed to restore the email.", { autoClose: 3000 });
              }
            };

            return (
              <div>
                <span>{data.message || "Email deleted successfully!"}</span>
                <Link
                  to=""
                  className="ms-3"
                  onClick={(e) => {
                    e.preventDefault();
                    undoMessageSeen();
                  }} // Attach the function here
                >
                  Undo
                </Link>
              </div>
            );
          },
          autoClose: 5000,
        },
        error: "Failed to delete email.",
      });
    } catch (error) {
      console.log("delete mail", error);
      toast.error("An error occurred while deleting the email.");
    }
  };

  const toggleRefresh = () => {
    setRefresh(true);
    fetchTabData();
  };

  // const toggleSearch = () => {
  //   setSearchToggle(!searchToggle);
  // };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  const allCheckboxChange = (checked) => {
    if (checked) {
      let tempArr = data?.map((item) => item.id);
      setCheckMail(tempArr);
    } else {
      setCheckMail([]);
    }
  };

  return (
    <div className="nk-ibx-head w-100">
      <div className="nk-ibx-head-actions">
        <div className="nk-ibx-head-check">
          <div className="custom-control custom-control-sm custom-checkbox">
            <input
              type="checkbox"
              checked={data?.length > 0 && data?.length === checkMail?.length}
              // disabled={data.length === 0}
              className="custom-control-input nk-dt-item-check"
              id="conversionAll"
              onChange={(e) => allCheckboxChange(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="conversionAll"></label>
          </div>
        </div>
        <ul className="nk-ibx-head-tools g-1">
          <li>
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
                toggleRefresh();
              }}
              className="btn btn-icon btn-trigger"
            >
              <span className="header-body-img">
                <img className={refresh ? "refresh-spin" : ""} src={refreshimg} alt="refresh" height={16} />
              </span>
            </a>
          </li>
          <li className="d-none d-sm-block">
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
                selectorDeleteInbox();
              }}
              className={`btn btn-icon btn-trigger`}
            >
              <span className="header-body-img">
                <img src={trash} alt="trash" height={16} />
              </span>
            </a>
          </li>

          {/* {checkMail.length === 1 && (
            <li className="d-sm-block">
              <a
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                  seMarkAsRead();
                }}
                className="btn btn-icon btn-trigger"
              >
                <span className="header-body-img">
                  <img
                    src={showRead ? MarkAsRead : MarkAsUnRead} // Toggle based on showRead state
                    alt={showRead ? "mark-as-unread" : "mark-as-read"}
                    height={16}
                  />
                </span>
              </a>
            </li>
          )} */}

          {checkMail.length > 0 && (
            <li className="d-sm-block">
              <a
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                  seMarkAsRead();
                }}
                className="btn btn-icon btn-trigger"
              >
                <span className="header-body-img">
                  <img
                    src={showRead ? MarkAsRead : MarkAsUnRead}
                    alt={showRead ? "mark-as-read" : "mark-as-unread"}
                    height={16}
                  />
                </span>
              </a>
            </li>
          )}

          {isOnTrashPage && checkMail.length > 0 && (
            <li className="d-sm-block">
              <a
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                  undoTrash();
                }}
                className="btn btn-icon btn-trigger"
              >
                <span className="header-body-img">
                  <img src={reply} alt={"undo"} height={16} />
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
      <div>
        <ul className="nk-ibx-head-tools g-1">
          <li>
            <a
              href="#item"
              // onClick={(ev) => {
              //   ev.preventDefault();
              //   prevPage();
              // }}
              className={`btn btn-trigger btn-icon btn-tooltip ${currentPage - 1 === 0 ? "disabled" : ""}`}
            >
              <Icon name="arrow-left"></Icon>
            </a>
          </li>
          <li>
            <a
              href="#item"
              // onClick={(ev) => {
              //   ev.preventDefault();
              //   nextPage();
              // }}
              // className={`btn btn-trigger btn-icon btn-tooltip ${
              //   pageNumbers[pageNumbers.length - 1] === currentPage || pageNumbers.length === 0 ? "disabled" : ""
              // }`}
              className={`btn btn-trigger btn-icon btn-tooltip`}
            >
              <Icon name="arrow-right"></Icon>
            </a>
          </li>
          <li>
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
                toggleSearch();
              }}
              className="btn btn-trigger btn-icon search-toggle toggle-search"
            >
              <span className="header-body-img">
                <img src={search} alt="search" height={20} />
              </span>
            </a>
          </li>
          <li className="me-n1 d-lg-none">
            <a
              href="#item"
              // onClick={(ev) => {
              //   ev.preventDefault();
              //   setAside(!aside);
              // }}
              className="btn btn-trigger btn-icon toggle"
            >
              <Icon name="menu-alt-r"></Icon>
            </a>
          </li>
        </ul>
      </div>
      <div className={`search-wrap ${searchToggle ? "active" : ""}`}>
        <div className="search-content">
          <a
            href="#item"
            onClick={(ev) => {
              ev.preventDefault();
              toggleSearch();
            }}
            className="search-back btn btn-icon toggle-search"
          >
            <Icon name="arrow-left"></Icon>
          </a>
          <input
            type="text"
            className="form-control border-transparent form-focus-none"
            placeholder="Search by username"
            onChange={(e) => onSearchChange(e)}
          />
          <button className="search-submit btn btn-icon">
            <span className="header-body-img">
              <img src={search} alt="search" height={20} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InboxBodyHead;
