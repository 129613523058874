import React, { useState, useEffect } from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import "../css/audioRec.css"; // Import your CSS file
import audio from "../../images/applogos/images/audio.png";

const AudioRecorder = ({ onAudioRecorded, toggleMessageModal, isModalOpen }) => {
  const [recordState, setRecordState] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  // Start recording
  const startRecording = () => {
    setRecordState(RecordState.START);
    setIsRecording(true);
    startTimer();
  };

  // Stop recording
  const stopRecording = () => {
    setRecordState(RecordState.STOP);
    setIsRecording(false);
    clearInterval(intervalId); // Stop timer when recording stops
  };

  // Timer for recording time
  const startTimer = () => {
    const id = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
    setIntervalId(id);
  };

  // Reset timer
  const resetTimer = () => {
    setTimer(0);
  };

  // When recording stops, we get the audio data
  const onStop = (data) => {
    setAudioData(data);
    resetTimer();
  };

  // Handle when the user chooses to discard the recording
  const discardRecording = () => {
    setAudioData(null);
    resetTimer();
    setIsRecording(false);
  };

  // Handle recording complete action
  const handleRecordingComplete = () => {
    if (audioData) {
      onAudioRecorded(audioData);
      toggleMessageModal();
    }
  };

  // Format the timer (seconds to MM:SS)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // Cleanup interval and stop recording on modal close or component unmount
  useEffect(() => {
    // If modal is closed, stop recording
    if (!isModalOpen && isRecording) {
      stopRecording();
    }

    return () => {
      clearInterval(intervalId);
      if (isRecording) {
        stopRecording();
      }
    };
  }, [isModalOpen, isRecording, intervalId]);

  return (
    <div className="audio-recorder">
      {!audioData ? (
        <div className="audio-container">
          <div className="audio-container-pulse pulse">
            <div className="audio-preview-circle ">
              <AudioReactRecorder state={recordState} onStop={onStop} />
            </div>
          </div>
          <div className="controls">
            {isRecording ? (
              <span className="recording-time">{formatTime(timer)}</span>
            ) : (
              <img src={audio} height={90} />
            )}
            {!isRecording ? (
              <button type="button" className="record-btn" onClick={startRecording}>
                <div className="record-circle"></div>
              </button>
            ) : (
              <button type="button" className="record-btn recording" onClick={stopRecording}>
                <div className="record-circle"></div>
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="recorded-audio">
          <div className="rec-audio-prev">
            <audio className="audio-preview" src={audioData.url} controls />
          </div>
          <div className="audio-options">
            <button onClick={discardRecording} type="button" className="btn btn-primary">
              Discard Audio
            </button>
            <button onClick={handleRecordingComplete} type="button" className="btn btn-primary">
              Add to Attachments
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
