import React, { useContext, useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { Icon, UserAvatar, TooltipComponent } from "../../../components/Component";
import { findUpper, formatReplyDateTime, formatWithRelativeTime, getCookie } from "../../../utils/Utils";
import { contacts } from "./InboxData";
import trash from "../../../images/applogos/images/trash.png";
import replyImg from "../../../images/applogos/images/reply.png";
import moreImg from "../../../images/applogos/images/arrow_down.png";
import { createGlobalStyle } from "styled-components";
import { MyContext } from "../../ContextMail";
import parse from "html-react-parser";
import RepliableMail from "./RepliableMail";
import { toast } from "react-toastify";

function formatUTCToLocalTime(utcDateString) {
  const date = new Date(utcDateString);

  // Get the individual parts of the date
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Get the time in 12-hour format
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  return `${day} ${month}, ${year}, ${hours}:${minutes} ${ampm}`;
}

const formatRecipientName = (recipient) => {
  const cookieEmail = getCookie("email");

  // If the recipient's name is empty, use the part before the '@' from the email
  const name = recipient.name ? recipient.name : recipient.mail.split("@")[0];

  // Show 'me' if the email matches the user's email
  return cookieEmail === recipient.mail ? "me" : name;
};

// Function to render recipients with optional prefix (for 'cc:' and 'bcc:')
const renderEmails = (recipients, prefix = "") => {
  if (!recipients || recipients.length === 0) return null;

  return (
    <>
      {recipients.map((recipient, index) => (
        <span key={index}>
          {prefix} {formatRecipientName(recipient)}
          {index < recipients.length - 1 ? ", " : ""}
        </span>
      ))}
    </>
  );
};

const RecipientsDisplay = ({ reply }) => {
  const { recipient, cc, bcc } = reply || {};

  return (
    <>
      To: {renderEmails(recipient)}
      {cc && cc.length > 0 && <>, {renderEmails(cc, "CC:")}</>}
      {bcc && bcc.length > 0 && <>, {renderEmails(bcc, "BCC:")}</>}
    </>
  );
};

const renderEmailList = (recipients, label) => {
  if (!recipients || recipients.length === 0) return null;

  return recipients.map((recipient, index) => (
    <li key={index}>
      <span className="label">{index === 0 ? label : ""}</span>{" "}
      <span className="info">
        <a href="#item" onClick={(ev) => ev.preventDefault()}>
          {recipient.name} <RepliableMail email={recipient.mail} />
        </a>
      </span>
    </li>
  ));
};

const stripHtmlTags = (html) => {
  // Replace HTML tags with a space
  return html
    .replace(/<\/?[^>]+(>|$)/g, " ")
    .replace(/\s+/g, " ")
    .trim();
};

const InboxReplyItem = ({ reply, deleteMessage, mailData, setRepMsg, replies }) => {
  const [user, setUser] = useState();
  const [collapse, setCollapse] = useState(false);

  const { recipient, cc, bcc } = reply?.to || {};

  const [isReplyVisible, setIsReplyVisible] = useState(false);

  const toggleReplyVisibility = () => {
    setIsReplyVisible(!isReplyVisible);
  };

  useEffect(() => {
    // let defaultUser = contacts.find((item) => item.id === reply.userId);
    // setUser(defaultUser);
    setUser(reply);
  }, [reply]);

  const toggleCollapse = (ev) => {
    setCollapse(!collapse);
  };
  const handleChildClick = (ev) => {
    // Prevent event from bubbling up to parent
    ev.stopPropagation();
  };

  const openAttachment = (attachments) => {
    attachments.forEach((at) => {
      var link = document.createElement("a");
      link.download = at.name;
      // link.target = "_blank";
      link.href = at.path;
      link.click();
    });
  };

  const downloadAttachments = async (attachments) => {
    const toastIds = {}; // To keep track of toast ids for each file

    for (const file of attachments) {
      // Show a pending toast for each file
      const toastId = toast.loading(`Downloading ${file.name}...`);
      toastIds[file.name] = toastId;

      try {
        // Fetch the file content
        const response = await fetch(file.path, { mode: "cors" });

        // Check if the fetch was successful
        if (response.ok) {
          const blob = await response.blob(); // Get the file as a blob

          // Create a download link
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = file.name; // File name
          link.style.display = "none"; // Hide the link
          document.body.appendChild(link);
          link.click(); // Trigger the download
          document.body.removeChild(link); // Clean up the DOM
          URL.revokeObjectURL(url); // Release the blob URL

          // Optionally, you could handle success here if needed
          // toast.update(toastIds[file.name], { render: `Downloaded ${file.name}`, type: toast.TYPE.SUCCESS, autoClose: 5000 });
        } else {
          console.error(`Failed to download: ${file.name}`);
          // Show error toast
          toast.update(toastIds[file.name], {
            render: `Failed to download ${file.name}`,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error(`Error downloading ${file.name}:`, error);
        // Show error toast
        toast.update(toastIds[file.name], {
          render: `Error downloading ${file.name}`,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } finally {
        // Remove the toast after processing
        toast.dismiss(toastIds[file.name]);
      }
    }
  };

  const findParentReply = (replyId) => {
    return replies.find((item) => item.replyId === parseInt(replyId)); // Ensure replyId is parsed correctly
  };

  const renderReplyChain = (currentReply) => {
    if (!currentReply || !currentReply.replyOf) return null;

    const parentReply = findParentReply(currentReply.replyOf);

    return (
      <div style={{ marginLeft: "5px" }}>
        <div className="pt-3">
          {formatReplyDateTime(parentReply.utctime)} <strong>{parentReply?.name}</strong>{" "}
          <RepliableMail email={parentReply.mail} /> wrote:
          <div style={{ whiteSpace: "pre-wrap", borderLeft: "1px solid grey", paddingLeft: "8px", marginLeft: "5px" }}>
            {parentReply?.replyMessage ? (
              <div style={{ whiteSpace: "pre-wrap" }}>{parse(parentReply?.replyMessage)}</div>
            ) : (
              <br />
            )}
            {renderReplyChain(parentReply)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nk-ibx-reply-item nk-reply-item">
      {user && (
        <React.Fragment>
          <div className={`nk-reply-header nk-ibx-reply-header`} onClick={toggleCollapse}>
            <div className="nk-reply-desc">
              {reply.sending ? (
                <Spinner />
              ) : (
                <UserAvatar
                  className="nk-reply-avatar"
                  text={findUpper(user.name)}
                  theme={user.theme}
                  image={user.profile_image_path}
                />
              )}
              <div className="nk-reply-info">
                <div className="nk-reply-author lead-text">{user.name}</div>

                {collapse ? (
                  <span>{stripHtmlTags(reply.replyMessage)}</span>
                ) : (
                  <UncontrolledDropdown className="nk-reply-msg-info">
                    <DropdownToggle
                      tag="a"
                      className="dropdown-toggle sub-text dropdown-indicator"
                      href="#toggle"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleChildClick(ev);
                      }}
                    >
                      {/* to {getCookie("email") === reply?.to?.mail ? "me" : reply?.to?.mail} */}
                      <RecipientsDisplay reply={reply?.to} />
                    </DropdownToggle>
                    <DropdownMenu className="width-mx-content">
                      <ul className="nk-reply-msg-meta">
                        <li>
                          <span className="label">from:</span>{" "}
                          <span className="info">
                            <a href="#item" onClick={(ev) => ev.preventDefault()}>
                              {user.name} <RepliableMail email={user.mail} />
                              {/* &lt;{user.mail}&gt; */}
                            </a>
                          </span>
                        </li>
                        <div className="my-1"></div>
                        {/* To Recipients */}
                        {recipient && recipient.length > 0 && renderEmailList(recipient, "To:")}
                        <div className="my-1"></div>
                        {/* CC Recipients */}
                        {cc && cc.length > 0 && renderEmailList(cc, "CC:")}
                        <div className="my-1"></div>
                        {/* BCC Recipients */}
                        {bcc && bcc.length > 0 && renderEmailList(bcc, "BCC:")}
                        <div className="my-1"></div>
                        <li>
                          <span className="label">Date:</span>{" "}
                          <span className="info">
                            <a href="#item" onClick={(ev) => ev.preventDefault()}>
                              {/* {`${reply.date}, ${reply.time}`} */}
                              {formatUTCToLocalTime(reply.utctime)}
                            </a>
                          </span>
                        </li>
                        {/* <li>
                        <span className="label">mailed-by:</span>{" "}
                        <span className="info">
                          <a href="#item" onClick={(ev) => ev.preventDefault()}>
                            {user.mail}
                          </a>
                        </span>
                      </li> */}
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {/* <div className="nk-reply-msg-excerpt">
                  At SiloCloud, we offer a suite of blockchain services edge-based Web 3.0 development tools to drive
                  your business forward. Gain full visibility into every blockchain transaction and take part as a node
                  validator to earn token rewards while engaging with your preferred applications. Realize your vision
                  by building, hosting, and streaming your dreams on our decentralized network!
                </div> */}
              </div>
            </div>
            <ul className="nk-reply-tools g-1">
              {reply.attachment?.length > 0 && (
                <li className="attach-msg">
                  <Icon name="clip-h"></Icon>
                </li>
              )}
              <li className="date-msg">
                {/* <span className="date">{reply.date}</span> */}
                <span className="date">{formatWithRelativeTime(reply.utctime)}</span>
              </li>
              {/* <li className="replyto-msg" onClick={() => replyTo(user.mail)}> */}
              <li
                className="replyto-msg"
                onClick={(ev) => {
                  setRepMsg(reply.replyId);
                  handleChildClick(ev);
                }}
              >
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-icon btn-trigger btn-tooltip"
                  // icon="curve-up-left"
                  path={replyImg}
                  id="ibx-msg-reply"
                  direction="top"
                  text="Reply"
                />
              </li>
              {/* <li className="more-actions">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="dropdown-toggle btn btn-trigger btn-icon"
                  >
                    <Icon name="more-v"></Icon>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            replyTo(user.mail);
                          }}
                        >
                          <Icon name="reply-fill"></Icon>
                          <span>Reply to</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            forwardTo();
                          }}
                        >
                          <Icon name="forward-arrow-fill"></Icon>
                          <span>Forward</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#item"
                          onClick={(ev) => {
                            ev.preventDefault();
                            deleteMessage(reply.replyId);
                          }}
                        >
                          <Icon name="trash-fill"></Icon>
                          <span>Delete this</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li> */}
            </ul>
          </div>

          <div className={`nk-reply-body nk-ibx-reply-body is-shown ${collapse ? "d-none" : ""}`}>
            <div className="nk-reply-entry entry">
              {/* {reply.replyMessage.map((msgItem, index) => (
                <p key={index}>{msgItem}</p>
              ))} */}
              {/* <p style={{ whiteSpace: "pre-wrap" }}>{reply.replyMessage}</p> */}
              <div style={{ whiteSpace: "pre-wrap" }} className="ql-editor p-0">
                {parse(reply.replyMessage)}
              </div>
            </div>

            {reply.replyOf && (
              <>
                <div className="mt-2"></div>
                <div className="d-inline pointer" onClick={toggleReplyVisibility} id={"tooltip" + reply.replyId}>
                  <img src={moreImg} height={4} />
                </div>
                <UncontrolledTooltip autohide={true} placement={"top"} target={"tooltip" + reply.replyId}>
                  {isReplyVisible ? "Hide full content" : "Show trimmed content"}
                </UncontrolledTooltip>
              </>
            )}

            <div
              className={`nk-reply-body nk-ibx-reply-body is-shown ${!isReplyVisible ? "d-none" : ""} ql-editor p-0`}
            >
              {renderReplyChain(reply)}
            </div>

            {reply.attachment?.length > 0 && (
              <div className="attach-files">
                <ul className="attach-list p-1">
                  {reply.attachment.map((att, index) => (
                    <li className="attach-item m-1" key={index} onClick={() => openAttachment([att])}>
                      <a className="download" href="#" onClick={(ev) => ev.preventDefault()}>
                        <Icon name="img"></Icon>
                        <span>{att.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="attach-foot">
                  <span className="attach-info">{reply.attachment.length} files attached</span>
                  <a
                    className="attach-download link"
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      downloadAttachments(reply.attachment);
                    }}
                  >
                    <Icon name="download"></Icon>
                    <span>Download All</span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default InboxReplyItem;
